export const COLLECTION_USER = "COLLECTION_USER";
// appendHere
export const COLLECTION_CUSTOMER14860 = "COLLECTION_CUSTOMER14860";
export const COLLECTION_ROOMTYPESETTINGSMEAL72145 =
  "COLLECTION_ROOMTYPESETTINGSMEAL72145";
export const COLLECTION_AMENITY2092 = "COLLECTION_AMENITY2092";
export const COLLECTION_BOOKING30512 = "COLLECTION_BOOKING30512";
export const COLLECTION_MEALS32280 = "COLLECTION_MEALS32280";
export const COLLECTION_ALLOTMENT70748 = "COLLECTION_ALLOTMENT70748";
export const COLLECTION_PACKAGEOVERVIEW28297 =
  "COLLECTION_PACKAGEOVERVIEW28297";
export const COLLECTION_PACKAGEIMAGE7308 = "COLLECTION_PACKAGEIMAGE7308";
export const COLLECTION_TRANSFER86814 = "COLLECTION_TRANSFER86814";
export const COLLECTION_USER63684 = "COLLECTION_USER63684";
export const COLLECTION_EXCLUSIVE_HOTEL_ROOM_TYPE47552 =
  "COLLECTION_EXCLUSIVE_HOTEL_ROOM_TYPE47552";
export const COLLECTION_AIRPORT23903 = "COLLECTION_AIRPORT23903";
export const COLLECTION_PACKAGEPOLICY18428 = "COLLECTION_PACKAGEPOLICY18428";
export const COLLECTION_EXCLUSIVEHOTEL64774 = "COLLECTION_EXCLUSIVEHOTEL64774";
export const COLLECTION_ROOMIMAGE27052 = "COLLECTION_ROOMIMAGE27052";
export const COLLECTION_HOTEL28355 = "hotels";
export const COLLECTION_CATEGORY81497 = "categories";
export const COLLECTION_COUPONDETAILS67751 = "COLLECTION_COUPONDETAILS67751";
export const COLLECTION_TOUR48116 = "COLLECTION_TOUR48116";
export const COLLECTION_PACKAGEFLIGHT78497 = "COLLECTION_PACKAGEFLIGHT78497";
export const COLLECTION_HOTELIMAGE94307 = "COLLECTION_HOTELIMAGE94307";
export const COLLECTION_AIRLINE61016 = "COLLECTION_AIRLINE61016";
export const COLLECTION_PACKAGEHOTEL55435 = "COLLECTION_PACKAGEHOTEL55435";
export const COLLECTION_ROOM40231 = "COLLECTION_ROOM40231";
export const COLLECTION_DEPARTURECITY6794 = "COLLECTION_DEPARTURECITY6794";
export const COLLECTION_CITY83431 = "cities";
export const COLLECTION_CUSTOMERCOUPON37856 = "COLLECTION_CUSTOMERCOUPON37856";
export const COLLECTION_HOTELPACKAGEDURATION73001 =
  "COLLECTION_HOTELPACKAGEDURATION73001";
export const COLLECTION_EXCLUSIVE_HOTEL_ROOM_TYPE_AVAILABLE =
  "COLLECTION_EXCLUSIVE_HOTEL_ROOM_TYPE_AVAILABLE";
export const COLLECTION_BOOKING30512_HOTEL = "COLLECTION_BOOKING30512_HOTEL";
export const COLLECTION_PARTNER = "COLLECTION_PARTNER";
export const COLLECTION_PARTNER_BANNER = "COLLECTION_PARTNER_BANNER";
export const COLLECTION_PACKAGES = "COLLECTION_PACKAGE";
export const COLLECTION_PACKAGE_POLICY = "COLLECTION_PACKAGE_POLICY";
export const COLLECTION_EMAIL = "mail";
export const COLLECTION_BOOKING_PACKAGE = "COLLECTION_BOOKING_PACKAGE";
export const COLLECTION_BOOKING_PACKAGE_COUNT =
  "COLLECTION_BOOKING_PACKAGE_COUNT";
