import { pick, sortBy, values } from "lodash";
import { IAirport } from "../common/typings";

/* eslint-disable prettier/prettier */
interface IAirportMap { [key: string]: IAirport; }
export const airports: IAirportMap = {
  LCA: {value: 'LCA', label: 'Λάρνακα', city: 'LARC', country: 'CY'},
  ATH: {value: 'ATH', label: 'Αθήνα', city: 'ATHE', country: 'GR'},
  PFO: {value: 'PFO', label: 'Πάφος', city: 'PAPH', country: 'CY'},
  SKG: {value: 'SKG', label: 'Θεσσαλονίκη', city: 'THESS', country: 'GR'},
  LHR: {value: 'LHR', label: 'Λονδίνο Heathrow', city: 'LOND', country: 'UK'},
  MAN: {value: 'MAN', label: 'Μάντσεστερ', city: 'MANC', country: 'UK'},
  CDG: {value: 'CDG', label: 'Παρίσι Charles de Gaulle', city: 'PARI', country: 'FR'},
  AMS: {value: 'AMS', label: 'Άμστερνταμ', city: 'AMS', country: 'NL'},
  BCN: {value: 'BCN', label: 'Βαρκελώνη', city: 'BARC', country: 'ES'},
  MAD: {value: 'MAD', label: 'Μαδρίτη', city: 'MADR', country: 'ES'},
  MXP: {value: 'MXP', label: 'Μιλάνο Malpensa', city: 'MILA', country: 'IT'},
  BGY: {value: 'BGY', label: 'Μιλάνο Bergamo', city: 'MILA', country: 'IT'},
  LIN: {value: 'LIN', label: ' Μιλάνο Linate', city: 'MILA', country: 'IT'},
  CIA: {value: 'CIA', label: 'Ρώμη Ciampino', city: 'ROME', country: 'IT'},
  FCO: {value: 'FCO', label: ' Ρώμη Fiumicino', city: 'ROME', country: 'IT'},
  BHX: {value: 'BHX', label: 'Μπέρμινγκχαμ', city: 'BIRM', country: 'UK'},
  LGW: {value: 'LGW', label: ' Λονδίνο Gatwick', city: 'LOND', country: 'UK'},
  STN: {value: 'STN', label: ' Λονδίνο Stansted', city: 'LOND', country: 'UK'},
  LTN: {value: 'LTN', label: ' Λονδίνο Luton', city: 'LOND', country: 'UK'},
  SOF: {value: 'SOF', label: 'Σόφια', city: 'SOFI', country: 'BG'},
  ZRH: {value: 'ZRH', label: 'Ζυρίχη', city: 'ZURI', country: 'CH'},
  GVA: {value: 'GVA', label: 'Γενεύη', city: 'GENE', country: 'CH'},
  BSL: {value: 'BSL', label: 'Βασιλεία', city: 'BASE', country: 'CH'},
  BRN: {value: 'BRN', label: 'Βέρνη', city: 'BERN', country: 'CH'},
  KRK: {value: 'KRK', label: 'Κρακοβία', city: 'KRAK', country: 'PL'},
  WAW: {value: 'WAW', label: 'Βαρσοβία Chopin', city: 'WARS', country: 'PL'},
  WMI: {value: 'WMI', label: 'Βαρσοβία Modlin WMI', city: 'WARS', country: 'PL'},
  BVA: {value: 'BVA', label: 'Παρίσι Beauvais', city: 'PARI', country: 'FR'},
  MLA: {value: 'MLA', label: 'Μάλτα', city: 'VALL', country: 'MT'},
  DXB: {value: 'DXB', label: 'Ντουμπάι', city: 'DUBA', country: 'AE'},
  KBP: {value: 'KBP', label: 'Κίεβο Boryspil', city: 'KIEV', country: 'UA'},
  IEV: {value: 'IEV', label: 'Κίεβο Zhuliany', city: 'KIEV', country: 'UA'},
  LAS: {value: 'LAS', label: 'Λας Βέγκας', city: 'LA', country: 'US'},
  JFK: {value: 'JFK', label: 'Νέα Υόρκη John F. Kennedy', city: 'NY', country: 'US'},
  TLV: {value: 'TLV', label: 'Τελ Αβίβ', city: 'TELA', country: 'IL'},
  VIE: {value: 'VIE', label: 'Βιέννη', city: 'VIEN', country: 'AT'},
  RHO: {value: 'RHO', label: 'Ρόδος', city: 'RHOD', country: 'GR'},
  JSI: {value: 'JSI', label: 'Σκιάθος', city: 'SKIA', country: 'GR'},
  JMK: {value: 'JMK', label: 'Μύκονος', city: 'MYKO', country: 'GR'},
  CHQ: {value: 'CHQ', label: 'Χανιά', city: 'CHAN', country: 'GR'},
  HER: {value: 'HER', label: 'Ηράκλειο', city: 'HERA', country: 'GR'},
  JTR: {value: 'JTR', label: 'Σαντορίνη', city: 'SANT', country: 'GR'},
  ZTH: {value: 'ZTH', label: 'Ζάκυνθος', city: 'ZAKY', country: 'GR'},
  PAS: {value: 'PAS', label: 'Πάρος', city: 'PARO', country: 'GR'},
  YYZ: {value: 'YYZ', label: 'Τορόντο Pearson International', city: 'TORO', country: 'CA'},
  OTP: {value: 'OTP', label: 'Βουκουρέστι Otopeni', city: 'BUCH', country: 'RO'},
  IAS: {value: 'IAS', label: 'Ιάσιο', city: 'IASI', country: 'RO'},
  BUD: {value: 'BUD', label: 'Βουδαπέστη', city: 'BUDA', country: 'HU'},
  SVO: {value: 'SVO', label: 'Μόσχα Sheremetyevo', city: 'MOSC', country: 'RU'},
  DME: {value: 'DME', label: 'Μόσχα Domodedovo International', city: 'MOSC', country: 'RU'},
  LED: {value: 'LED', label: 'Αγία Πετρούπολη Pulkovo', city: 'SAINT', country: 'RU'},
  TBS: {value: 'TBS', label: 'Τιφλίδα', city: 'TBIL', country: 'GE'},
  PRG: {value: 'PRG', label: 'Πράγα', city: 'PRAG', country: 'CZ'},
  KIV: {value: 'KIV', label: 'Κισινάου', city: 'CHIS', country: 'MD'},
  BRU: {value: 'BRU', label: 'Βρυξέλλες International', city: 'BRUS', country: 'BE'},
  CRL: {value: 'CRL', label: 'Βρυξέλλες S. Charleroi', city: 'BRUS', country: 'BE'},
  LIS: {value: 'LIS', label: 'Λισαβώνα', city: 'LISB', country: 'PT'},
  OPO: {value: 'OPO', label: 'Πόρτο', city: 'PORT', country: 'PT'},
  TXL: {value: 'TXL', label: 'Βερολίνο Tegel', city: 'BERL', country: 'DE'},
  SXF: {value: 'SXF', label: ' Βερολίνο Schoenefeld', city: 'BERL', country: 'DE'},
  MUC: {value: 'MUC', label: 'Μόναχο', city: 'MUNI', country: 'DE'},
  HAM: {value: 'HAM', label: 'Αμβούργο', city: 'HAMB', country: 'DE'},
  MEX: {value: 'MEX', label: 'Μεξικό Juarez International', city: 'MC', country: 'MX'},
  EZE: {value: 'EZE', label: ' Μπουένος Άιρες Ministro Pistarini', city: 'BUEN', country: 'AR'},
  SIN: {value: 'SIN', label: 'Σιγκαπούρη', city: 'SING', country: 'SG'},
  DPS: {value: 'DPS', label: 'Μπαλί (Denpasar)', city: 'BALI', country: 'ID'},
  CGK: {value: 'CGK', label: 'Soekarno-Hatta Τζακάρτα', city: 'JAKA', country: 'ID'},
  HLP: {value: 'HLP', label: 'Halim Perdana Kusuma Τζακάρτα', city: 'JAKA', country: 'ID'},
  SYD: {value: 'SYD', label: 'Σύδνεϋ Kingsford Smith', city: 'SYDN', country: 'AU'},
  DOH: {value: 'DOH', label: 'Ντόχα Hamad International', city: 'DOAH', country: 'QA'},
  CAN: {value: 'CAN', label: ' Κουανγκτσόου', city: 'GUAN', country: 'CN'},
  PEK: {value: 'PEK', label: 'Πεκίνο', city: 'BEIJ', country: 'CN'},
  HND: {value: 'HND', label: 'Τόκυο Haneda', city: 'TOKY', country: 'JP'},
  NRT: {value: 'NRT', label: 'Τόκυο Narita', city: 'TOKY', country: 'JP'},
  MLE: {value: 'MLE', label: 'Μαλδίβες', city: 'MALE', country: 'MV'},
  HAV: {value: 'HAV', label: 'Αβάνα', city: 'HAVA', country: 'CU'},
  BOG: {value: 'BOG', label: 'Μπογκοτά', city: 'BOGO', country: 'CO'},
  GIG: {value: 'GIG', label: 'Ρίο ντε Τζανέιρο', city: 'RIO', country: 'BR'},
  MVD: {value: 'MVD', label: 'Μοντεβιδέο', city: 'MONT', country: 'UY'},
  DUB: {value: 'DUB', label: 'Δουβλίνο', city: 'DUBL', country: 'IE'},
  OSL: {value: 'OSL', label: 'Όσλο ', city: 'OSLO', country: 'NO'},
  ARN: {value: 'ARN', label: 'Στοκχόλμη Arlanda', city: 'STOC', country: 'SE'},
  NYO: {value: 'NYO', label: 'Στοκχόλμη Skavsta', city: 'STOC', country: 'SE'},
  BEG: {value: 'BEG', label: 'Βελιγράδι', city: 'BELG', country: 'RS'},
  MSQ: {value: 'MSQ', label: 'Μινσκ', city: 'MINSK', country: 'BY'},
  CPH: {value: 'CPH', label: 'Κοπεγχάγη', city: 'COPE', country: 'DK'},
  HEL: {value: 'HEL', label: 'Ελσίνκι', city: 'HELS', country: 'FI'},
  DEL: {value: 'DEL', label: 'Νέο Δελχί', city: 'NEWD', country: 'IN'},
  RKV: {value: 'RKV', label: 'Ρέικιαβικ', city: 'REYK', country: 'IS'},
  SJJ: {value: 'SJJ', label: 'Σαράγεβο', city: 'SARA', country: 'BA'},
  CAI: {value: 'CAI', label: 'Κάιρο', city: 'CAIR', country: 'EG'},
  JIK: {value: 'JIK', label: 'Ικαρία', city: 'IKAR', country: 'GR'},
  BER: {value: "BER", label: "Βερολίνο Brandenburg", city: "BERL", country: "DE"},
  WRO: {value: "WRO", label: "Βρότσλαβ Nicolaus Copernicus Airport", city: "WROW", country: "PL"},
  ZAG: {value: "ZAG", label: "Ζάγκρεπ", city: "ZAGR", country: "HR"},
  BTS: {value: "BTS", label: "Μπρατισλάβα", city: "BRAT", country: "SK"},
  CGN: {value: "CGN", label: "Κολονία", city: "EDDK", country: "DE"},
  ΑΜΜ: {value: "ΑΜΜ", label: "Αμμάν", city: "OJAI", country: "JO"},
  GDN: {value: "GDN", label: "Γκντανσκ", city: "EPGD", country: "PL"},
  TSF: {value: "TSF", label: "Βενετία Treviso", city: "LIPZ", country: "IT"},
  VCE: {value: "VCE", label: "Βενετία Marco Polo", city: "LIPZ", country: "IT"},
  IOA: {value: "IOA", label: "Ιωάννινα, Ελλάδα", city: "LGIO", country: "GR"},
  DWC: {value: "DWC", label: "Ντουμπάι Αλ Μακτούμ", city: "OMDB", country: "AE"},
  PVK: {value: "PVK", label: "Πρέβεζα", city: "LGPZ", country: "GR"},
  KTW: {value: "KTW", label: "Κάτοβιτσε", city: "EPKT", country: "PL"},
  CLJ: {value: "CLJ", label: "Κλουζ", city: "Ρουμανία", country: "RO"},
  CUN: {value: "CUN", label: "Κανκούν", city: "Κανκούν", country: "MX"},
  RAK: {value: "RAK", label: "Μαρακές", city: "GMMX", country: "MA"},
  SVQ: {value: "SVQ", label: "Σεβίλλη", city: "LEZL", country: "ES"},
  EFL: {value: "EFL", label: "Αργοστόλι, Κεφαλονιά", city: "LGKF", country: "GR"},
  JKH: {value: "JKH", label: "Χίος", city: "LGHI", country: "GR"},
  VAR: {value: "VAR", label: "Βάρνα", city: "LBWN", country: "BG"},
  BOJ: {value: "BOJ", label: "Bourgas", city: "LBBG", country: "BG"},
  ZNZ: {value: "ZNZ", label: "Ζανζιβάρη", city: "HTZA", country: "TZ"},
  KUN: {value: "KUN", label: "Κάουνας", city: "EYKA", country: "LT"},
  RIX: {value: "RIX", label: "Ρίγα", city: "EVRA", country: "LV"},
  TLL: {value: "TLL", label: "Ταλιν", city: "EETN", country: "EE"},
  KIX: {value: "KIX", label: "Οσάκα", city: "RJOO", country: "JP"},
  TLS: {value: "TLS", label: "Τουλούζη", city: "LFBO", country: "FR"},
  MJT: {value: "MJT", label: "Μυτιλήνη", city: "LGMT", country: "GR"},
  HKT: {value: "HKT", label: "Πουκέτ", city: "VTSP", country: "TH"},
  BKK: {value: "BKK", label: "Μπανγκόκ", city: "VTBS", country: "TH"},
  VNO: {value: "VNO", label: "Βίλνιους", city: "EYVI", country: "LT"},
  IST: {value: "IST", label: "Κωνσταντινούπολη", city: "LTFM", country: "TR"},
  FRA: {value: "FRA", label: "Φρανκφούρτη", city: "EDDF", country: "DE"},
  NAP: {value: "NAP", label: "Νάπολη", city: "LIRN", country: "IT"},
  DUN: {value: "DUN", label: "Δουβλίνο", city: "EIDW", country: "IE"},
  BOD: {value: "BOD", label: "Μπορντό", city: "LFBD", country: "FR"},
  TLD: {value: "TLD", label: "Τουλούζη", city: "LFBO", country: "FR"},
  PDL: {value: "PDL", label: "Αζόρες Ponta Delgada", city: "LPPD", country: "PT"},
  FNC: {value: "FNC", label: "Μαδέιρα", city: "LPMA", country: "PT"},
  PKX: {value: "PKX", label: "Πεκίνο", city: "ZBAA", country: "CN"},
  PVG: {value: "PVG", label: "Σανγκάι", city: "ZSPD", country: "CN"},
  HAN: {value: "HAN", label: "Ανόι", city: "VVVV", country: "VN"},
  SGN: {value: "SGN", label: "Χο Τσι Μινχ - Σαικον", city: "VVTS", country: "VN"},
  SSH: {value: "SSH", label: "Σαρμ Ελ Σέιχ", city: "HESH", country: "EG"},
  CMB: {value: "CMB", label: "Κολόμπο", city: "VCBI", country: "LK"},
  SJO: {value: "SJO", label: "Σαν Χοσέ", city: "KSJC", country: "CR"},
  LIR: {value: "LIR", label: "Λιβερία", city: "MRLB", country: "LR"},
  AXD: {value: "AXD", label: "Αλεξανδρούπολη", city: "LGAL", country: "GR"},
  JNB: {value: "JNB", label: "Γιοχάνεσμπουργκ", city: "FAOR", country: "ZA"},
  CPT: {value: "CPT", label: "Cape Town", city: "FACT", country: "ZA"},
  MRS: {value: "MRS", label: "Μασσαλία", city: "LFML", country: "FR"},
  FLR: {value: "FLR", label: "Φλωρεντία", city: "LIRQ", country: "IT"},
  EDI: {value: "EDI", label: "Εδιμβούργο", city: "EGPH", country: "UK"},
  DAR: {value: "DAR", label: "Νταρ ες Σαλάμ", city: "HTZA", country: "TZ"},
  AGP: {value: "AGP", label: "Μάλαγα", city: "LEMG", country: "ES"},
  AUH: {value: "AUH", label: "Άμπου Ντάμπι", city: "OMAA", country: "AE"},
  USM: {value: "USM", label: "Κο Σαμουι", city: "VTSM", country: "TH"},
  KUL: {value: "KUL", label: "Κουάλα Λουμπούρ", city: "WMKK", country: "MY"},
  FMM: {value: "FMM", label: "Μεμμινγκεν", city: "EDJA", country: "DE"},
  NAV: {value: "NAV", label: "Καππαδοκία", city: "LTAZ", country: "TR"},
  LPA: {value: "LPA", label: "Γκραν Κανάρια", city: "GCLP", country: "ES"},
  RVN: {value: "RVN", label: "Ροβανιέμι", city: "EFRO", country: "FI"},
  EVN: {value: "EVN", label: "Γερεβάν", city: "UDYZ", country: "AM"},
  KUT: {value: "KUT", label: "Κουτάισι", city: "UGKO", country: "GE"},
  CFU: {value: "CFU", label: "Κέρκυρα", city: "LGKR", country: "GR"},
  BAH: {value: "BAH", label: "Μπαχρέιν", city: "OBBI", country: "BH"},
}







export const airportsOptions = sortBy(values(airports), ['label']).map(airport => pick(airport, ['value', 'label']));



