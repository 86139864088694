/* eslint-disable no-console */
import axios from "axios";

const config = () => ({
  headers: {
    "Access-Control-Max-Age": 1000,
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: `Bearer ${tokenInit || accessToken}`,
  },
});

export const axiosGet = (path: string) => axios.get(path, config());

export const axiosPost = <T>(
  path: string,
  data: T,
  port = 5003,
  tokenInit?: string,
) => axios.post(path, data, config());
