/* eslint-disable no-console */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input, Logo } from "../../atoms";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal, openModal } from "../../../redux/data/modals/modalReducer";
import { signInWithEmail } from "../../../firestore/firebaseService";
import { ICreds } from "../../../common/types";
import { LoginLogoWrapper, LoginNewUserWrapper } from "./LoginForm.style";

export const LoginFormPoupas: React.FC = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleOnClose = () => {
    dispatch(closeModal());
  };
  const handleSubmit = async () => {
    try {
      const values: ICreds = await form.validateFields();
      setLoading(true);
      await signInWithEmail({
        email: values.email,
        password: values.password,
      });

      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError("Problem with user name or password");
    }
  };
  const handleSignUp = () => {
    dispatch(
      openModal({
        modalType: "SignInFormPoupas",
        modalProps: { title: "Σύνδεση ή Δημιουργία Λογαριασμού" },
      }),
    );
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      closable={true}
      okText={""}
      cancelText="Κλείσιμο"
      onCancel={handleOnClose}
      footer={false}
    >
      <LoginLogoWrapper>
        <Logo />
      </LoginLogoWrapper>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Email" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Κωδικός"
          rules={[{ required: true, message: "Κωδικός" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Κωδικός"
          />
        </Form.Item>
        <Button type="primary" onClick={handleSubmit} block>
          {" "}
          Σύνδεση
        </Button>
        <Divider></Divider>
        <div className="sign-in-up">
          {" "}
          Ξεχάσατε το Κωδικό σας;{" "}
          <Link to="/forget-password"> Πατήστε εδώ για να τον Επαναφέρετε</Link>
        </div>
        <LoginNewUserWrapper>
          <div className="loginNewUser-action">
            <Divider> Νέος Πελάτης</Divider>
            <div className="loginNewUser-link">
              {" "}
              <Button type="link" onClick={handleSignUp}>
                {" "}
                Δημιουργία Νέου <br /> Λογαριασμού
              </Button>
            </div>
          </div>
          <div className="loginNewUser-or">
            {" "}
            <span>ή / Ή</span>
          </div>
          <div className="loginNewUser-action">
            <Divider> Επισκέπτης</Divider>
            <div className="loginNewUser-link">
              {" "}
              <Button type="link" onClick={handleOnClose}>
                {" "}
                Συνέχεια ως <br /> επισκέπτης
              </Button>
            </div>
          </div>
        </LoginNewUserWrapper>
      </Form>
    </ModalWrapper>
  );
};
