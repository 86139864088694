import styled from "styled-components";
export const HotelWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;
  display: flex;
  flex-direction: column;

  .hotel {
    position: relative;
    flex: 1;
    &-body {
      display: flex;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &-left {
      overflow-y: hidden;
      width: 300px;
      background-color: #f8f8f8;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    &-right {
      background-color: #fff;
      padding: 0px;
      display: flex;
      flex-direction: column;
      width: 400px;
      background-color: #f8f8f8;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
    &-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
    }
    &-title {
      width: 100%;
      text-align: center;
      font-size: 21px;
      margin: 0px auto;
      color: #0c3d72;
      padding: 20px;
      font-weight: 700;
      background-color: #ffffff;
    }
    &-header {
      background: #f3f3f3;
      height: 50px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      &-filter {
        border-right: 1px solid #ffffff;
        button {
          font-size: 18px;
        }
      }
      &-result {
        display: flex;
        gap: 20px;
        align-items: center;
        > .btn {
          background-color: rgb(76, 175, 80);
          border-color: rgb(76, 175, 80);
          color: rgb(255, 255, 255);
          text-align: center;
          border-radius: 5px;
          font-size: 13px;
          height: 28px;
          line-height: 13px;
        }
      }
      &-sorting {
        flex: 1;
        text-align: right;
        padding-right: 20px;
        .title {
          font-size: 14px;
          color: #22335d;
          padding-right: 10px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #d9d9d9;
          border-radius: 0px;
          width: 200px;
          text-align: left;
          @media (max-width: 440px) {
            width: 150px;
          }
        }
      }

      @media (min-width: 1200px) {
        padding-left: 20px;
      }
    }
    &-booking-details {
      background-color: #fff;
      position: absolute;
      bottom: 0;
      padding: 0px;
      left: 0;
      right: 0;
      box-shadow: 0px -1px 6px 1px rgb(64 87 109 / 22%);
      z-index: 80;
      text-align: center;
      border-top: 1px solid #cfcfcf;
      padding: 10px;
      &-btn {
        height: 80px;
        width: 100%;

        font-size: 15px;
        font-family: "Avenir";
        padding: 0;
        margin: 0;
        &-btn {
          background-color: #1d3bf8;
          height: 64px;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-family: "Avenir";
          font-weight: 900;
        }
        &-label {
          span {
            font-weight: 900;
          }
        }
      }
    }
    &-result {
      flex: 1;
      overflow-y: auto;
    }
  }
  .hide {
    transform: translateX(-100%);
    width: 0;
  }
  .hideRight {
    transform: translateX(100%);
    /* width: 0; */
  }
`;
export const PackageViewFrontEndWrapper = styled.div`
  background-color: #f6f8f9;
  padding: 40px 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .package-view-frontend {
    &-body {
      display: flex;
      margin: auto;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &-left {
      overflow-y: auto;
      flex: 1;
      padding: 30px;
      @media only screen and (max-width: 600px) {
        padding: 0px;
      }
      /* margin: 0 auto;
      max-width: 1000px; */
    }
    &-right {
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 0px;
      width: 400px;
      display: flex;
      flex-direction: column;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
    &-booking-details {
      background-color: #fff;
      position: absolute;
      bottom: 0;
      padding: 0px;
      left: 0;
      right: 0;
      box-shadow: 0px -1px 6px 1px rgb(64 87 109 / 22%);
      z-index: 80;
      text-align: center;
      border-top: 1px solid #cfcfcf;
      padding: 10px;
      &-btn {
        height: 80px;
        width: 100%;

        font-size: 15px;
        font-family: "Avenir";
        padding: 0;
        margin: 0;
        &-btn {
          background-color: #1d3bf8;
          height: 64px;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-family: "Avenir";
          font-weight: 900;
        }
        &-label {
          span {
            font-weight: 900;
          }
        }
      }
    }
  }
  .hide {
    transform: translateX(100%);
    width: 0;
  }
`;
