/* eslint-disable no-console */
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { ICategory, ICity, ISelectOption } from "../../../common/typings";
import { Button, Form, Space } from "../../atoms";
import { SelectField } from "../commonFields/SelectField";
import { periodTypeOptions } from "../../../enums";
import { HotelSearchBoxWrapper } from "./HotelSearchBox.style";
interface IProps {
  cities: ICity[];
  categories?: ICategory[];
  callBack?: () => void;
  adultNumbers?: number;
  childrenNumber?: number;
  infant?: number;
  city?: string;
  period?: string;
}

const maxNumber = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];
export const HotelSearchBox: React.FC<IProps> = ({
  cities,
  callBack,
  adultNumbers = 2,
  childrenNumber = 0,
  infant = 0,
  city = "all",
  period = "all",
}) => {
  const history = useNavigate();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = React.useState(false);
  const [searchCities, setSearchCities] = React.useState<ISelectOption[]>([]);
  // const [searchCategories, setSearchCategories] = React.useState<
  //   ISelectOption[]
  // >([]);
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values: any = await form.validateFields();
      const searchParams = new URLSearchParams({
        city: `${values.city}`,
        period: `${values.period}`,
        adultNumbers: `${values.adultNumbers}`,
        childrenNumber: `${values.childrenNumber}`,
        infant: `${values.infant}`,
      });
      history(`/hotels?${searchParams.toString()}`);
      callBack && callBack();
      setLoading(false);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const defaultCities = cities.map((selectedCity) => ({
      value: selectedCity.code,
      label: selectedCity.name,
    }));
    setSearchCities([
      {
        value: "all",
        label: formatMessage({
          defaultMessage: "Any Where",
          id: "d0ml+9",
        }),
      },
      ...defaultCities,
    ]);
  }, [cities]);

  return (
    <HotelSearchBoxWrapper>
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        initialValues={{ adultNumbers, childrenNumber, infant, city, period }}
      >
        <SelectField
          label={"Πόλη"}
          name="city"
          options={searchCities}
          showSearch={false}
        />
        <SelectField
          label={"Περίοδος"}
          name="period"
          options={[
            {
              value: "all",
              label: "Οποιαδήποτε",
            },
            ...periodTypeOptions,
          ]}
          showSearch={false}
        />
        <Row gutter={8}>
          <Col span={8}>
            <SelectField
              label="Ενήλικες 12+"
              name="adultNumbers"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              options={maxNumber.filter((v) => v.value > 0)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={8}>
            {" "}
            <SelectField
              label="Παιδιά 2-11 ετών"
              name="childrenNumber"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              options={maxNumber}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={8}>
            <SelectField
              label="Βρέφη 0-23ν"
              name="infant"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              options={maxNumber.filter((v) => v.value < 2)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Space style={{ width: "100%" }}></Space>
        <Button
          loading={loading}
          onClick={handleSubmit}
          className="search-button"
        >
          {" "}
          <SearchOutlined />{" "}
          <FormattedMessage
            description="Search"
            defaultMessage="Search"
            id="2NXYYu"
          />
        </Button>
      </Form>
    </HotelSearchBoxWrapper>
  );
};
