/* eslint-disable no-console */
import React from "react";
import { Alert, Button, Form, Space } from "../../atoms";
import { InputField, InputTextAreaField } from "../commonFields";
import { sendContactUsForm } from "../../../firestore/firestoreService";
import { ContactUsFormWrapper } from "./ContactUs.style";

export const ContactUsForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [send, setSend] = React.useState(false);
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values: any = await form.validateFields();
      await sendContactUsForm({
        name: values.name || "",
        email: values.email || "",
        phoneNumber: values.phoneNumber || "",
        message: values.message || "",
      });
      form.resetFields();
      setSend(true);
      setLoading(false);
    } catch (errorInfo) {
      console.log(errorInfo);
      setLoading(false);
    }
  };
  return (
    <ContactUsFormWrapper>
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        className="request-form"
      >
        {send && <Alert message="Email Send Successfully" type="success" />}
        <div className="request-form-title">Φόρμα επικοινωνίας</div>

        <InputField
          label={"Ονοματεπώνυμο"}
          name="name"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Email address"}
          name="email"
          placeholder="Email address"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Τηλέφωνο"}
          name="phoneNumber"
          placeholder="Τηλέφωνο"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputTextAreaField
          label={"Μήνυμα"}
          name="message"
          placeholder="Μήνυμα"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          style={{ width: "100%" }}
        />
        <Space style={{ width: "100%" }}></Space>
        <Button
          loading={loading}
          onClick={handleSubmit}
          type="primary"
          block
          className="request-form-button"
        >
          Υποβολή
        </Button>
      </Form>
    </ContactUsFormWrapper>
  );
};
