import React from "react";
import { Col, DatePicker, Form, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { InputField, SelectField } from "..";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { Button } from "../../atoms";
import { BookingWrapper } from "./Booking.style";

interface IProps {
  form: any;
  partnerId: string;
}
const partnerNameGr = process.env.REACT_APP_PARTNER_NAME_GR;

export const BookingCustomerInput: React.FC<IProps> = ({ form, partnerId }) => {
  const { authenticated } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const compareToFirstEmail = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue(["customer", "email"]) === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The two emails that you entered do not match!"),
      );
    },
  });

  const handleLogin = () => {
    dispatch(
      openModal({
        modalType: "LoginFormPoupas",
        modalProps: { title: "Σύνδεση" },
      }),
    );
  };

  React.useEffect(() => {
    if (!authenticated && partnerId === "POUPAS") {
      handleLogin();
    }
  }, [authenticated]);
  return (
    <BookingWrapper>
      <div className="booking-container">
        {!authenticated && partnerId === "POUPAS" && (
          <>
            <Button onClick={handleLogin} type="primary" block>
              Login{" "}
            </Button>
          </>
        )}
        <div className="booking-title">Στοιχεία μέλους της {partnerNameGr}</div>
        <Row gutter={24}>
          <Col span={8} xs={24} sm={8}>
            <SelectField
              label="Τίτλος"
              name={["customer", "title"]}
              rules={[
                {
                  required: true,
                  message: "Τίτλος",
                },
              ]}
              placeholder="Τίτλος"
              options={[
                { value: "MR", label: "Κος" },
                { value: "MS", label: "Κα" },
              ]}
            />
          </Col>
          <Col span={8} xs={24} sm={8}>
            <InputField
              label="Όνομα"
              name={["customer", "name"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Όνομα"
            />
          </Col>
          <Col span={8} xs={24} sm={8}>
            <InputField
              label="Επίθετο"
              name={["customer", "surname"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Επίθετο"
            />
          </Col>

          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Διεύθυνση Email"
              name={["customer", "email"]}
              rules={[
                {
                  type: "email",
                  message: "Διεύθυνση Email",
                },
                {
                  required: true,
                  message: "Διεύθυνση Email",
                },
              ]}
              placeholder="Διεύθυνση Email"
            />
          </Col>
          {!authenticated && (
            <Col span={8} xs={24} sm={12}>
              <InputField
                label="Επιβεβαίωση διεύθυνσης Email"
                name="confirmEmailAddress"
                rules={[
                  {
                    required: true,
                    message: "Επιβεβαίωση διεύθυνσης Email",
                  },
                  compareToFirstEmail(form),
                ]}
                placeholder="Επιβεβαίωση διεύθυνσης Email"
              />
            </Col>
          )}
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Τηλέφωνο Επικοινωνίας"
              name={["customer", "phone"]}
              rules={[
                {
                  required: true,
                  message: "Τηλέφωνο Επικοινωνίας",
                },
              ]}
              placeholder="Τηλέφωνο Επικοινωνίας"
            />
          </Col>

          {["POED", "PASYDY"].includes(partnerId || "") && (
            <>
              <Col span={8} xs={24} sm={12}>
                <InputField
                  label="Αριθμός Ταυτότητας"
                  name={["customer", "idNumber"]}
                  rules={[
                    {
                      required: true,
                      message: "Αριθμός Ταυτότητας",
                    },
                  ]}
                  placeholder="Αριθμός Ταυτότητας"
                  help=""
                />
              </Col>
              <Col span={8} xs={24} sm={12}>
                <Form.Item
                  label="Ημ. Γεννήσεως"
                  name={["customer", "dateOfBirth"]}
                  rules={[
                    {
                      required: true,
                      message: "Ημ. Γεννήσεως",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={"Ημερομηνία γέννησης"}
                    style={{ width: "100%" }}
                    disabledDate={(current) => current >= moment()}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>{" "}
      </div>
    </BookingWrapper>
  );
};
