/* eslint-disable no-console */
import React from "react";
import { Space } from "antd";
import Search from "antd/lib/input/Search";
import { CloseOutlined } from "@ant-design/icons";
import { IPackageHotelPublic, IRoomInput } from "../../../common/typings";
import {
  getBookingTotal,
  onApplyCoupon,
  removeCoupon,
} from "../../../firestore/firestoreService";
import { Alert, Button } from "../../atoms";
import { CartTotalBtnWrapper } from "./Cart.style";
interface ICartTotalBtnProps {
  submit: (type: string) => () => void;
  hotels: IPackageHotelPublic[];
  rooms: IRoomInput[];
  loading: boolean;
  luggages: {
    numberOf10KGLuggage: number;
    numberOf20KGLuggage: number;
    price10KGLuggage: number;
    price20KGLuggage: number;
  };
  discount: number;
  onRequest?: boolean;
  isTabletOrMobile?: boolean;
  totalPartialPayment: number;
  cancellationPolicy: string;
  refundableDate: string;
  totalNonRefundableAmount: number;
  allowPartialPayment: boolean;
  bookingId: string;
  customerId: string;
  partnerId: string;
  totalPrice: number;
  couponCode: string;
  couponValue: number;
  loadingPayment: boolean;
  errorPayment: string;
}
export const CartTotalBookingBtn: React.FC<ICartTotalBtnProps> = ({
  submit,
  hotels,
  rooms,
  loading,
  luggages,
  discount,
  onRequest,
  isTabletOrMobile,
  totalPartialPayment,
  cancellationPolicy,
  refundableDate,
  totalNonRefundableAmount,
  allowPartialPayment,
  bookingId,
  customerId,
  partnerId,
  totalPrice,
  couponCode,
  couponValue,
  loadingPayment,
  errorPayment,
}) => {
  const [finalPrice, setFinalPrice] = React.useState(0);
  const [couponLoading, setCouponLoading] = React.useState(false);
  const [removeCouponLoading, setRemoveCouponLoading] = React.useState(false);
  const [couponError, setCouponError] = React.useState("");
  const [couponDiscount, setCouponDiscount] = React.useState(0);
  const handleSubmitCoupon = async (value: string) => {
    try {
      setCouponLoading(true);
      const results: any = await onApplyCoupon({
        couponCode: value,
        bookingId,
        type: "PACKAGE",
        customerId,
        partnerId,
      });
      setCouponDiscount(results.discountValue);
      setCouponLoading(false);
      setCouponError("");
    } catch (error: any) {
      console.log(error);
      setCouponLoading(false);
      setCouponError(error.message || error);
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      setRemoveCouponLoading(true);
      await removeCoupon(bookingId);
      setRemoveCouponLoading(false);
      setCouponDiscount(0);
      setCouponError("");
    } catch (error: any) {
      console.log(error);
      setRemoveCouponLoading(false);
      setCouponError(error.message || error);
    }
  };

  React.useEffect(() => {
    const total = getBookingTotal(
      hotels || [],
      rooms || [],
      luggages,
      discount,
      couponDiscount,
    );
    setFinalPrice(total);
  }, [hotels, rooms, luggages, couponDiscount]);
  React.useEffect(() => {
    if (couponCode !== "") {
      setCouponDiscount(couponValue);
    }
  }, [couponCode]);
  return (
    <CartTotalBtnWrapper>
      {errorPayment !== "" && <div>{errorPayment}</div>}
      {!onRequest && (
        <div className="cart-total-coupon">
          <div className="cart-total-coupon-text">
            Έχετε κάποιο εκπτωτικό κουπόνι ή κωδικό έκπτωσης; Παρακαλώ εισάγετε
            τον πιο κάτω.
          </div>
          <Space className="cart-total-coupon-space">
            <div>Κωδικός έκπτωσης: </div>
            <Search
              placeholder="Κωδικός έκπτωσης"
              enterButton="Υποβολή"
              onSearch={handleSubmitCoupon}
              loading={couponLoading}
            />
          </Space>
          {couponError !== "" && <Alert message={couponError} type="error" />}
        </div>
      )}
      {couponDiscount > 0 && (
        <div className="cart-total-total">
          <span>Σύνολο</span>
          <span className="cart-total-total-price">€{finalPrice}</span>
        </div>
      )}
      {couponDiscount > 0 && (
        <div className="cart-total-coupon-total">
          <span>Εκπτωτικό Κουπόνι ({couponCode})</span>
          <span className="cart-total-coupon-total-price">
            - €{couponDiscount}{" "}
            <Button
              style={{ fontSize: 14 }}
              onClick={handleRemoveCoupon}
              loading={removeCouponLoading}
              type="text"
            >
              <CloseOutlined />
            </Button>
          </span>
        </div>
      )}
      <div className="cart-total-total">
        <span> Τελική Τιμή.</span>
        <span className="cart-total-total-price">€{totalPrice}</span>
      </div>

      {["REFUNDABLE"].includes(cancellationPolicy) && allowPartialPayment && (
        <div className="cart-total-cancellation-policy">
          <span>
            *Το πακέτο διακοπών που έχετε επιλέξει διαθέτει Πολιτική με δικαίωμα
            Πλήρης Ακύρωσης μέχρι τις <strong>{refundableDate}</strong>
          </span>
          <span>
            Προχωρήσετε με κράτηση τώρα χωρίς κανένα άγχος μέχρις τις{" "}
            <strong>{refundableDate}</strong> εάν τα σχέδια σας αλλάξουν.
          </span>
        </div>
      )}
      {["PARTIAL_REFUNDABLE"].includes(cancellationPolicy) &&
        allowPartialPayment && (
          <div className="cart-total-cancellation-policy">
            <span>
              *Το πακέτο διακοπών που έχετε επιλέξει διαθέτει Πολιτική με
              δικαίωμα Μερικής Ακύρωσης μέχρι τις{" "}
              <strong>{refundableDate}</strong>.
            </span>
            <span>
              Εάν προχωρήσετε με κράτηση τώρα τότε το ποσόν των{" "}
              <strong>€{totalNonRefundableAmount}</strong> θα δεσμευτεί χωρίς
              κανένα δικαίωμα επιστροφής χρημάτων εάν τα πλάνα σας αλλάξουν.
            </span>
            <span>
              *Παρακαλώ γνωρίζετε πως οι κρατήσεις με δικαίωμα Μερικής Ακύρωσης
              πολλές φορές είναι εφικτό να αλλαχθούν με κάποιο πέναλτι + την
              εκάστοτε διαφορά ναύλου και διαμονής.
            </span>
          </div>
        )}
      <div className="cart-total-actions">
        {!onRequest && allowPartialPayment && (
          <Space
            direction={!isTabletOrMobile ? "horizontal" : "vertical"}
            style={{
              width: "100%",
              gap: 20,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ width: "100%" }}>
              <div className="cart-total-text">
                Προτιμώ να πληρώσω για ολόκληρη τη κράτηση μου <br /> αξίας{" "}
                <span style={{ fontWeight: 700, fontSize: 18 }}>
                  €{totalPrice}
                </span>{" "}
                από τώρα.
              </div>
              <Button
                className="cart-total-btn"
                type="primary"
                onClick={submit("FULL_PAYMENT")}
                loading={loadingPayment}
              >
                Πλήρης Πληρωμή
              </Button>
            </div>
            <div style={{ width: "100%" }}>
              <div className="cart-total-text">
                Κρατήστε σήμερα το Πακέτο σας και <br /> πληρώστε μόνο{" "}
                <span style={{ fontWeight: 700, fontSize: 18 }}>
                  €{totalPartialPayment}
                </span>
              </div>
              <Button
                className="cart-total-btn"
                type="primary"
                onClick={submit("PARTIAL_PAYMENT")}
                loading={loadingPayment}
              >
                Προχώρα σε Μερική Πληρωμή
              </Button>
            </div>
          </Space>
        )}
        {!allowPartialPayment && !onRequest && (
          <Button
            className="cart-total-btn"
            onClick={submit("FULL_PAYMENT")}
            loading={loadingPayment}
          >
            Πλήρης Πληρωμή
          </Button>
        )}
        {onRequest && (
          <Button
            className="cart-total-btn"
            onClick={submit("")}
            loading={loading}
          >
            Αίτημα κράτησης
          </Button>
        )}
      </div>
    </CartTotalBtnWrapper>
  );
};
