/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  fetchCity83431FromFirestore,
  fetchPartnerBannerFromFirestore,
  listenToPartnerFromFirestore,
} from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Footer, HotelSearchBox, Loader } from "../../molecules";
import { HomePageWrapper } from "../Home/Home.style";
import { MyGalleryWrapper } from "../../../../Domain/HomePage/HotelPage.style";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { IPartner, IPartnerBanner } from "../../../common/types";
const partnerId = process.env.REACT_APP_SITE_ID || "POUPAS";
export const HotelHome: React.FC = () => {
  const dispatch = useDispatch();
  const [cities, setCities] = React.useState([]);
  const [localLoading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const [partner, setPartner] = React.useState<IPartner>();
  const [banners, setBanners] = React.useState<IPartnerBanner[]>([]);
  userFirestoreDoc({
    query: () => listenToPartnerFromFirestore(partnerId),
    data: setPartner,
    shouldExecute: !!partnerId,
    deps: [dispatch, partnerId],
    local: true,
    localSetLoader: setLoading,
  });
  useFirestoreCollection({
    query: () =>
      fetchPartnerBannerFromFirestore(partnerId, [
        {
          type: "filter",
          name: "display",
          opr: "==",
          value: "HOTEL_PAGE",
        },
      ]),
    data: setBanners,
    shouldExecute: !!partnerId,
    local: true,
    deps: [dispatch, partnerId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: "countryCode",
          opr: "==",
          value: "CY",
        },
      ]),
    data: setCities,
    local: true,
    deps: [dispatch],
  });
  if (localLoading) return <Loader color="#fff" />;
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <div
                  className="bgimg bg-hotel"
                  style={
                    !isMobile
                      ? {
                          background: `url(${partner?.hotelHeaderBanner?.[0]?.url || "/assets/images/Background-Poupas-for-Holidays.jpg"})
        right top/cover fixed`,
                        }
                      : {}
                  }
                ></div>
                <div className="heading">
                  <div className="flights-search-header">
                    <span
                      className="blue-color"
                      style={{
                        color: isMobile
                          ? "#fff"
                          : partner?.hotelHeaderTitleColor,
                      }}
                    >
                      {partner?.hotelHeaderTitle}
                    </span>
                  </div>
                  <div>
                    <HotelSearchBox cities={cities} categories={[]} />
                  </div>
                </div>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>

        {banners?.map((banner) => (
          <div className="section" key={banner.id}>
            <div className="section-heading">
              <h2 className="blue-color">{banner.title}</h2>
              {banner.subtitle && <div>{banner.subtitle}</div>}
            </div>
            <MyGalleryWrapper>
              <Row gutter={10}>
                {banner?.banners?.map((bannerImage) => (
                  <Col span={12} xs={24} sm={12} key={bannerImage.id}>
                    <Link to={bannerImage.url || ""}>
                      <img
                        src={bannerImage.image?.[0]?.url || ""}
                        alt={bannerImage?.title || ""}
                        style={{
                          width: isMobile ? 300 : 400,
                          height: isMobile ? 200 : 300,
                        }}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </MyGalleryWrapper>
          </div>
        ))}
        <Footer isMobile={false} />
      </div>
    </HomePageWrapper>
  );
};
