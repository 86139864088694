import { values } from "lodash";
import { ISelectOption } from "../common/types";

interface IPartnerTypesMap {
  [key: string]: ISelectOption;
}
export const travelerType: IPartnerTypesMap = {
  ORGANIZED: {
    label: "Οργανωμένα",
    value: "ORGANIZED",
  },
  FOR_COUPLES: {
    label: "Για Ζευγαρια",
    value: "FOR_COUPLES",
  },
  FAMILIES: {
    label: "Για Οικογένειες",
    value: "FAMILIES",
  },
  PARTIES: {
    label: "Για Παρέες",
    value: "PARTIES",
  },
  SOLO_TRAVELERS: {
    label: "Solo Ταξιδιώτες",
    value: "SOLO_TRAVELERS",
  },
  HONEYMOON: {
    label: "Γαμήλιο Ταξίδι",
    value: "HONEYMOON",
  },
  ADVENTURE_TRIPS: {
    label: "Ταξίδια Περιπέτειας",
    value: "ADVENTURE_TRIPS",
  },
  SPORT_TRIPS: {
    label: "Αθλητικά Γεγονότα",
    value: "SPORT_TRIPS",
  },
  CONCERT_TRIPS: {
    label: "Μουσικά Γεγονότα",
    value: "CONCERT_TRIPS",
  },
  LUXURY_TRIPS: {
    label: "Ταξίδια Πολυτελείας",
    value: "LUXURY_TRIPS",
  },
  PARTIAL_PAYMENT: {
    label: "Με Μερική Πληρωμή",
    value: "PARTIAL_PAYMENT",
  },
  FLY_CRUISE: {
    label: "Fly and Cruise",
    value: "FLY_CRUISE",
  },
};

export const travelerTypeOptions = values(travelerType);
