/* eslint-disable no-console */
import React from "react";
import { Link } from "react-router-dom";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Space } from "../../atoms";
import { FooterWrapper } from "./Footer.style";

interface IHeaderProps {
  isMobile: boolean;
}
const partnerEmail =
  process.env.REACT_APP_SITE_EMAIL || "reservations@poupas.com.cy";

export const Footer: React.FC<IHeaderProps> = ({ isMobile }) => (
  <FooterWrapper>
    <div className="footer-contact-us">
      <Link to="/contact-us" className="footer-contact-us-title">
        Επικοινωνήστε μαζί μας
      </Link>
      <div className="footer-contact-us-text">
        <span>Τηλεφωνικώς:</span>
        7008 7003 ή +35722252508
      </div>
      <div className="footer-contact-us-text">
        <span>Email:</span>
        <Link to={`mailto:${partnerEmail}`}>{partnerEmail}</Link>
      </div>
      <div className="footer-contact-us-text">
        {/* <span>Social Media:</span> */}
        <Space size={20}>
          <a
            href="https://www.facebook.com/poupasforholidays"
            target="_blank"
            rel="noreferrer"
            className="footer-contact-us-icon"
          >
            {" "}
            <FacebookOutlined />
          </a>
          <a
            href="https://www.instagram.com/poupasforholidays"
            target="_blank"
            rel="noreferrer"
            className="footer-contact-us-icon"
          >
            {" "}
            <InstagramOutlined />
          </a>
        </Space>
      </div>
    </div>
    <div className="footer-logos">
      <img src="/assets/gov_logos.jpg" alt="" style={{ marginBottom: 10 }} />
      Copyright 2025. All Rights Reserved.
    </div>
  </FooterWrapper>
);
