/* eslint-disable no-console */
import React from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { BookingCustomerInput } from "../../molecules/Booking/BookingCustomerInput";
import { BookingCommentInput } from "../../molecules/Booking/BookingCommentInput";
import { Form, notification } from "../../atoms";
import { CartTotalHotelBookingBtn, HotelCart, Loader } from "../../molecules";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  listenToBooking30512HotelFromFirestore,
  updateBooking30512HotelInFirestore,
} from "../../../firestore/firestoreService";
import { IHotelBookingInput } from "../../../common/types";
import { BookingRoomInput } from "../../molecules/Booking";
import { jccConvertAmount } from "../../../common/util/util";
import { axiosGet, axiosPost } from "../../../config/axios";
import { HotelWrapper } from "./Hotel.style";

const VIVA_PAYMENT_BASED_URL =
  "https://us-central1-poupas-9e426.cloudfunctions.net/vivaHotelPayment";
const partnerId = process.env.REACT_APP_SITE_ID || "";
export const HotelBooking: React.FC = ({}) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<any>();
  const history = useNavigate();
  const [loadingPayment, setLoading] = React.useState(false);
  const [errorPayment, setError] = React.useState("");
  const [showCart, setShowCart] = React.useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const formRefPartialPayment = React.useRef<any>();
  const dispatch = useDispatch();
  const params = useParams();
  const [booking, setBooking] = React.useState<IHotelBookingInput>();
  const { selectedCustomer14860 } = useSelector(
    (state: any) => state.customers,
  );

  const bookingId = params.bookingId || "";
  const handleOnFinish = (type: string) => async () => {
    try {
      const values: IHotelBookingInput = await form.validateFields();
      // eslint-disable-next-line no-console
      console.log(values.rooms);
      await updateBooking30512HotelInFirestore(bookingId, {
        ...booking,
        paymentStatus: type === "ON_REQUEST" ? "ON_REQUEST" : "IN_PROGRESS",
        comment: values?.comment || "",
        customer:
          {
            ...values.customer,
            dateOfBirth: moment(values.customer?.dateOfBirth || "").format(
              "YYYY-MM-DD",
            ),
          } || null,
        customerId: selectedCustomer14860?.id || "",
        rooms: booking?.rooms.map((room: any) => ({
          ...room,
          mainGuest: {
            ...values.roomsMainGuest[room.id],
            dateOfBirth: moment(
              values.roomsMainGuest[room.id]?.dateOfBirth || "",
            ).format("YYYY-MM-DD"),
          },
        })),
        initialPayment: type,
      });
      if (type === "PARIETAL_PAYMENT") {
        await handlePayment("PARTIAL_PAYMENT");
      } else if (type === "FULL_PAYMENT") {
        await handlePayment("FULL_PAYMENT");
      } else {
        history(`/hotel-booking-on-request-confirm/${bookingId}`);
      }
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  const handlePayment = async (type: string) => {
    try {
      setLoading(true);
      const { data } = await axiosGet(`${VIVA_PAYMENT_BASED_URL}/get-token`);
      if (data.token) {
        const { data: dataApi, status } = await axiosPost(
          `${VIVA_PAYMENT_BASED_URL}/hotel-booking-create-transaction`,
          { token: data.token, bookingId, type },
        );
        if (status === 200) {
          window.location.href = dataApi.checkOutUrl;
        }
      } else {
        throw new Error(`No Token`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  userFirestoreDoc({
    query: () => listenToBooking30512HotelFromFirestore(bookingId),
    data: setBooking,
    shouldExecute: !!bookingId,
    deps: [dispatch, bookingId],
    local: true,
  });
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);
  React.useEffect(() => form.resetFields, [selectedCustomer14860, booking]);
  if (booking && booking?.status !== "NEW") {
    history("/");
  }
  if (!booking) return <Loader title="Booking Loading" color={"#fff"} />;
  return (
    <HotelWrapper>
      <div className="hotel-body">
        <div className="hotel-result">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{
              ...booking,
              customer: {
                name: selectedCustomer14860?.name || "",
                email: selectedCustomer14860?.email || "",
                phone: selectedCustomer14860?.phone || "",
                id: "",
              },
            }}
          >
            <BookingCustomerInput form={form} partnerId={partnerId} />
            {booking.rooms.map((room) => (
              <BookingRoomInput
                key={room.id}
                title={""}
                hotelId={booking.hotelId}
                room={room}
                showDateOfBirth
              />
            ))}
            <BookingCommentInput />
            <CartTotalHotelBookingBtn
              submit={handleOnFinish}
              loading={false}
              discount={0}
              totalPartialPayment={booking.partialPayment}
              cancellationPolicy={""}
              refundableDate={booking.refundableDate}
              totalNonRefundableAmount={0}
              allowPartialPayment={true}
              bookingId={booking.id}
              customerId={booking.customerId}
              partnerId={booking.partnerId || ""}
              totalPrice={booking.totalPayment}
              couponCode={booking.couponCode}
              couponValue={booking.couponDiscount}
              loadingPayment={loadingPayment}
              errorPayment={errorPayment}
              finalPrice={booking.totalPrice}
              onRequest={!!booking.onRequest}
              isTabletOrMobile={isTabletOrMobile}
            />
          </Form>
        </div>
        <div className={classNames("hotel-right", { hideRight: showCart })}>
          <HotelCart
            rooms={booking.rooms}
            startDate={booking.checkIn}
            hotelId={booking.hotelId}
            exclusiveOfferId={booking.exclusiveOfferId}
            checkInCheckOut={`${booking.checkIn} - ${booking.checkOut}`}
            totalPrice={booking.totalPayment}
            sellPrice={booking.sellPrice}
            netPrice={booking.netPrice}
            onShowCart={handelShowCart}
            isTabletOrMobile={isTabletOrMobile}
          />
        </div>
      </div>
      <form
        method="post"
        name="paymentForm"
        id="fullPaymentForm"
        ref={(ref) => (formRef.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(booking?.totalPayment || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={booking?.signatureTotal || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
      <form
        method="post"
        name="paymentForm"
        id="partialPaymentForm"
        ref={(ref) => (formRefPartialPayment.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(booking?.partialPayment || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={booking?.signaturePartial || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
    </HotelWrapper>
  );
};
