/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Alert, Divider, Form, notification, Space } from "../../atoms";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { sendRequestSelectedPackageForm } from "../../../firestore/firestoreService";
import {
  HotelInfo,
  InputField,
  InputTextAreaField,
  PackageInfo,
  SelectField,
} from "../../molecules";
import { IPackageHotelPublic } from "../../../common/typings";
import { IPackage } from "../../../common/types";
import { formatReadableAddress } from "../../../common/util/util";

interface IProps {
  selectedRooms: any;
  packageHotel: IPackageHotelPublic;
  travelPackage: IPackage;
}
const roomType = [
  { value: "single", label: "(1 Ενήλικας) Μονόκλινο" },
  { value: "double", label: "(2 Ενήλικες) Δίκλινο Δωμάτιο" },
  { value: "triple", label: "(3 Ενήλικες) Τρίκλινο Δωμάτιο" },
  { value: "quadruple", label: "(4 Ενήλικες) Τετράκλινο Δωμάτιο" },
];
const childernNumber = [
  { value: 0, label: "Χωρίς παιδί" },
  { value: 1, label: "1 παιδί" },
  { value: 2, label: "2 Παιδιά" },
  { value: 3, label: "3 Παιδιά" },
  { value: 4, label: "4 Παιδιά" },
];

function convertRoomSelectionsToString(
  selectedRooms: Array<{ numberChilds: number; roomType: string }>,
): string {
  return selectedRooms
    .map((room, index) => {
      // Find matching roomType label
      const roomTypeItem = roomType.find((rt) => rt.value === room.roomType);
      const roomTypeLabel = roomTypeItem ? roomTypeItem.label : room.roomType; // fallback to the value if not found

      // Find matching child label
      const childItem = childernNumber.find(
        (cn) => cn.value === room.numberChilds,
      );
      const childLabel = childItem
        ? childItem.label
        : `${room.numberChilds} Παιδιά`; // fallback

      // Example output: "Room 1: (2 Ενήλικες) Δίκλινο Δωμάτιο, 2 Παιδιά"
      return `Room ${index + 1}: ${roomTypeLabel}, ${childLabel}`;
    })
    .join(" | "); // join multiple rooms with a separator, e.g., " | "
}
export const RequestPackageFormModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error] = React.useState("");

  const handleOnClose = () => {
    dispatch(closeModal());
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values: any = await form.validateFields();
      const rooms = convertRoomSelectionsToString(values.rooms || []);
      await sendRequestSelectedPackageForm({
        name: values.name || "",
        email: values.email || "",
        phoneNumber: values.phoneNumber || "",
        packageUrl: window?.location?.href || "",
        packageTitle: props.travelPackage.name || "",
        departureDate:
          moment(props.travelPackage.departureDate).format("DD/MM/YYYY") || "",
        returnDate:
          moment(props.travelPackage.returnDate).format("DD/MM/YYYY") || "",
        hotelName: props.packageHotel.hotel.name,
        rooms: rooms || "",
        message: values.message || "",
      });
      form.resetFields();
      notification.success({
        message: "Επιτυχώς",
        description: "Το μήνυμα σας στάληκε επιτυχώς!",
      });
      dispatch(closeModal());
      setLoading(false);
    } catch (errorInfo) {
      console.log(errorInfo);
      setLoading(false);
      notification.error({
        message: "Αποτυχία Αποστολής",
        description:
          "Δυστυχώς η αποστολή του μηνύματος σας Απέτυχε. Παρακαλώ προσπαθήστε ξανά.",
      });
    }
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      closable={true}
      okText={"Υποβολή"}
      onCancel={handleOnClose}
    >
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        className="request-form"
        initialValues={{ rooms: props.selectedRooms }}
      >
        <div className="request-form-title">
          Δεν βρήκατε το πακέτο που σας ενδιαφέρει;
        </div>
        <div className="request-form-sub-title">
          Συμπληρώστε τη πιο κάτω φόρμα σύμφωνα με την αναζήτηση σας και θα
          επικοινωνήσουμε εμείς μαζί σας εντός 24 εργάσιμων ωρών για να σας
          βοηθήσουμε
        </div>
        <Divider>Στοιχεία Επικοινωνίας</Divider>
        <InputField
          label={"Ονοματεπώνυμο"}
          name="name"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Email address"}
          name="email"
          placeholder="Email address"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <InputField
          label={"Τηλέφωνο"}
          name="phoneNumber"
          placeholder="Τηλέφωνο"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        />
        <Divider>Στοιχεία του πακέτου σας</Divider>
        <div style={{ backgroundColor: "#f8f8f8", padding: 10 }}>
          <h3 style={{ textAlign: "center" }}>Όνομα Πακέτου</h3>
          <PackageInfo
            title={props.travelPackage.name}
            departureCity={props.travelPackage.departureCity || {}}
            departureDate={props.travelPackage.departureDate || ""}
            returnDate={props.travelPackage.returnDate || ""}
            serviceInclude={props.travelPackage.serviceInclude || []}
            categories={[]}
            partner={props.travelPackage?.partnerId}
          />
        </div>
        <Divider />
        <div style={{ backgroundColor: "#f8f8f8", padding: 10 }}>
          <h3 style={{ textAlign: "center" }}>Όνομα Ξενοδοχείου</h3>
          <HotelInfo
            starRating={props.packageHotel.hotel.starRating}
            hotelName={props.packageHotel.hotel.name}
            address={
              formatReadableAddress(props.packageHotel.hotel?.address) || ""
            }
            imageUrl={props.packageHotel.hotel?.defaultImage?.url || ""}
            mealType={props.packageHotel.mealsType}
          />
        </div>
        <Divider />
        <div style={{ backgroundColor: "#f8f8f8", padding: 10 }}>
          <h3 style={{ textAlign: "center" }}>Δωμάτια</h3>
          <Form.List name="rooms">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    className="full-width traveller-selectors-room"
                    key={index}
                    align={index === 0 ? "center" : "baseline"}
                  >
                    <div className="traveller-selectors-des">
                      Δωμάτιο {index + 1}
                    </div>
                    <SelectField
                      name={[index, "roomType"]}
                      options={roomType}
                      label="Ενήλικας(ες)"
                      placeholder="Ενήλικας(ες)"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    />
                    <SelectField
                      name={[index, "numberChilds"]}
                      options={childernNumber}
                      label="Παιδί(α)"
                      placeholder="Παιδί(α)"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </div>
        <InputTextAreaField
          label={"Μήνυμα"}
          name="message"
          placeholder="Μήνυμα"
          style={{ width: "100%" }}
        />
      </Form>
    </ModalWrapper>
  );
};
