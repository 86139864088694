/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from "styled-components";
const partnerId = process.env.REACT_APP_SITE_ID!;
// theme is now fully typed
export const LogoWrapper = styled.div`
  width: ${partnerId === "POUPAS" ? "150px" : "auto"};
  img {
    height: 50px;
  }
`;
