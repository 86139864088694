/* eslint-disable no-console */
import moment from "moment";
import { find } from "lodash";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  IBooking,
  IBookingAgent,
  IPackageHotelPublic,
  IRoomInput,
  ILuggage,
} from "../../common/typings";
import firebase from "../../config/firebase";
import { calculateAmount, getRoomCost } from "../../common/util/util";
import { IBookingPackage } from "../../common/types";
import { db } from "./firestoreService";
import {
  COLLECTION_BOOKING_PACKAGE,
  COLLECTION_BOOKING_PACKAGE_COUNT,
  COLLECTION_PACKAGES,
} from "./firebaseConstants";

const functions = getFunctions();
const applyCoupon = httpsCallable(functions, "applyCoupon");
const removeCouponFn = httpsCallable(functions, "removeCouponFromBooking");
const getBookingDocIdFn = httpsCallable(
  functions,
  "getBookingDocIdByVivaOrderCode",
);

export async function fetchBookingDocId(vivaOrderCode: number) {
  try {
    const response: any = await getBookingDocIdFn({ vivaOrderCode });
    return response?.data?.docId;
  } catch (error) {
    throw error;
  }
}
export function listenToBookingFromFirestore(id: string) {
  return db.collection(COLLECTION_BOOKING_PACKAGE).doc(id);
}

export async function addBookingToFirestore(doc: Partial<IBookingPackage>) {
  try {
    console.log({ doc });
    const batch = db.batch();
    const extra: Partial<IBooking> = {};
    const countRef = db
      .collection(COLLECTION_BOOKING_PACKAGE_COUNT)
      .doc("count");
    const bookingRef = db.collection(COLLECTION_BOOKING_PACKAGE).doc();
    const count = (await countRef.get()).data();
    const total = await getBookingTotalFirebase(
      doc.travelPackageId || "",
      doc?.packageHotels || [],
      doc?.rooms || [],
      doc?.luggages,
      doc?.partnerDiscount || 0,
      //  doc?.discount,
      // doc?.discountOffer,
    );
    if (
      ["REFUNDABLE", "PARTIAL_REFUNDABLE"].includes(
        doc.cancellationPolicy || "",
      ) &&
      doc.allowPartialPayment
    ) {
      extra.totalPartialPayment = calculateAmount(
        +(doc.partialPayment || 0),
        doc?.rooms,
      );
      extra.totalRemainingAmount = total - +extra.totalPartialPayment;
      extra.totalNonRefundableAmount = calculateAmount(
        +(doc.nonRefundableAmount || 0),
        doc?.rooms,
      );
    }

    batch.update(countRef, {
      count: firebase.firestore.FieldValue.increment(1),
    });

    batch.set(bookingRef, {
      ...doc,
      bookingDate: firebase.firestore.FieldValue.serverTimestamp(),
      status: "NEW",
      paymentStatus: "PENDING",
      comment: "",
      bookingId: +(count?.count || 0) + 1,
      total,
      totalPrice: +total,
      ...extra,
      flights:
        doc.flights?.map((flight) => ({
          ...flight,
          departureDate: moment(flight.departureDate).toDate(),
          arrivalDate: moment(flight.arrivalDate).toDate(),
        })) || [],
    });
    await batch.commit();
    return bookingRef.id;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function updateBookingInFirestore(docId: string, doc: IBookingPackage) {
  console.log(doc);
  return db
    .collection(COLLECTION_BOOKING_PACKAGE)
    .doc(docId)
    .update({
      ...doc,
      flights:
        doc.flights?.map((flight) => ({
          ...flight,
          departureDate: moment(flight.departureDate).toDate(),
          arrivalDate: moment(flight.arrivalDate).toDate(),
        })) || [],
      passengers: doc.passengers.map((passenger) => ({
        ...passenger,
        dateOfBirth: moment(passenger.dateOfBirth).toDate(),
      })),
    });
}

export function updateBookingAgentInFirestore(
  docId: string,
  doc: IBookingAgent,
) {
  return db
    .collection(COLLECTION_BOOKING_PACKAGE)
    .doc(docId)
    .update({
      ...doc,
    });
}

export function getBookingTotal(
  packageHotels: IPackageHotelPublic[],
  rooms: IRoomInput[],
  luggage: ILuggage,
  discount: number,
  discountOffer?: number,
) {
  const packageHotelsPrice = packageHotels.reduce(
    (previousHotel, currentHotel) =>
      Number(previousHotel) +
      Number(
        rooms?.reduce(
          (previousRoom, currentPrice) =>
            Number(previousRoom) +
            getRoomCost(currentPrice, currentHotel, discount),
          0,
        ),
      ),
    0,
  );
  const luggagesPrice = luggage
    ? luggage.numberOf10KGLuggage * luggage.price10KGLuggage +
      luggage.numberOf20KGLuggage * luggage?.price20KGLuggage
    : 0;
  const total = packageHotelsPrice + luggagesPrice;

  const packageHotelsPriceWithDiscount = discountOffer
    ? Math.round(total - total * discountOffer)
    : total;
  return packageHotelsPriceWithDiscount;
}

export async function getBookingTotalFirebase(
  packageId: string,
  packageHotels: IPackageHotelPublic[],
  rooms: IRoomInput[],
  luggage: ILuggage | any,
  discount: number,
  discountOffer?: number,
) {
  try {
    const packageFromFirebase = await db
      .collection(COLLECTION_PACKAGES)
      .doc(packageId)
      .get();
    const selectedPackage = packageFromFirebase.data();
    if (!selectedPackage) {
      throw new Error();
    }
    const packageHotelsPrice = packageHotels.reduce(
      (previousHotel, currentHotel) =>
        Number(previousHotel) +
        Number(
          rooms?.reduce((previousRoom, currentPrice) => {
            const selectedHotel = find(selectedPackage?.hotels || [], [
              "id",
              currentHotel.id,
            ]);
            return (
              Number(previousRoom) +
              getRoomCost(currentPrice, selectedHotel, discount)
            );
          }, 0),
        ),
      0,
    );
    const luggagesPrice = luggage
      ? luggage.numberOf10KGLuggage *
          Number(selectedPackage?.price10KGLuggage || 0) +
        luggage.numberOf20KGLuggage *
          Number(selectedPackage?.price20KGLuggage || 0)
      : 0;
    const total = packageHotelsPrice + luggagesPrice;
    const packageHotelsPriceWithDiscount = discountOffer
      ? Math.round(total - total * discountOffer)
      : total;
    return packageHotelsPriceWithDiscount;
  } catch (err) {
    throw err;
  }
}

export async function onApplyCoupon({
  couponCode,
  bookingId,
  partnerId,
  type,
  customerId,
}: {
  couponCode: string;
  bookingId: string;
  partnerId: string;
  type: string;
  customerId: string;
}) {
  try {
    const result = await applyCoupon({
      couponCode,
      bookingId,
      partnerId,
      type,
      customerId,
    });
    return result.data;
  } catch (err: any) {
    console.log(err);
    throw err.message || err;
  }
}

export async function removeCoupon(bookingId: string) {
  try {
    const result: any = await removeCouponFn({ bookingId });
    if (result?.data?.success) {
      return true;
    }
    throw result.data.message;
  } catch (error: any) {
    throw error.message || error;
  }
}
