/* eslint-disable no-console */
import { Result } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams } from "react-router-dom";
import { ICity, ILuggage, IPolicy } from "../../../../common/typings";
import {
  fetchBookingDocId,
  listenToBookingFromFirestore,
} from "../../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import {
  Cart,
  ItemNotFound,
  Loader,
  PackageInfo,
  Passengers,
} from "../../../molecules";
import { listenToSelectedBooking } from "../../../../redux/data/booking/bookingActions";
import { PackageBookingConfirmFrontendWrapper } from "./PackageBookingConfirmFrontend.style";

const defaultLuggages: ILuggage = {
  numberOf10KGLuggage: 0,
  numberOf20KGLuggage: 0,
  price20KGLuggage: 0,
  price10KGLuggage: 0,
};
export interface IPackageBProps {
  onRequest?: boolean;
}
type DiscountType = "pasydyDiscount" | "poedDiscount";
const discountFieldName = process.env.REACT_APP_SITE_DISCOUNT as DiscountType;

export const PackageBookingConfirmFrontend: React.FC<IPackageBProps> = ({
  onRequest,
}) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const { loading, error } = useSelector((state: any) => state.async);
  const [bookingIdConf, setBookingIdConf] = React.useState<string>();
  const location = useLocation();
  const { selectedBooking: booking } = useSelector(
    (state: any) => state.bookings,
  );
  const queryParams = new URLSearchParams(location.search);
  const params = useParams();
  const bookingId = params.bookingId;

  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingIdConf || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingIdConf],
    shouldExecute: !!bookingIdConf,
  });
  const getCitiesNames = (cities: ICity[]) =>
    cities.map((c: ICity) => c.name).join(", ");
  const getServiceIncludes = (serviceIncludes: string[]) =>
    serviceIncludes.join(", ");

  React.useEffect(() => {
    if (!bookingId && queryParams.get("s")) {
      const getBookingId = async () => {
        const id = await fetchBookingDocId(+(queryParams.get("s") || 0));
        console.log(id);
        setBookingIdConf(id);
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBookingId();
    }
  }, [bookingId]);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/packages"
        redirectBtnText="Back to packages"
        itemType="Booking"
        itemId={bookingId || bookingIdConf || ""}
      />
    );
  }
  return (
    <PackageBookingConfirmFrontendWrapper>
      <div className="booking-confirm-body">
        {onRequest ? (
          <Result
            status="success"
            title={" Ευχαριστούμε."}
            subTitle={"Έχουμε λάβει το αίτημα κράτησης"}
          />
        ) : (
          <Result
            status="success"
            title={
              <FormattedMessage
                description="Successfully"
                defaultMessage="Successfully"
                id="VNBmrR"
              />
            }
            subTitle={
              <FormattedMessage
                description="An email with your booking details will arrive shortly"
                defaultMessage="An email with your booking details will arrive shortly"
                id="H5zQ4s"
              />
            }
          />
        )}

        <PackageInfo
          title={booking?.travelPackage?.name || "-"}
          departureCity={booking?.travelPackage?.departureCity || {}}
          departureDate={booking?.travelPackage?.departureDate || ""}
          returnDate={booking?.travelPackage?.returnDate || ""}
          serviceInclude={booking?.travelPackage?.serviceInclude || []}
          categories={booking?.travelPackage?.categories || []}
        />
        <div className="booking-confirm-image">
          <img src={booking?.travelPackage?.image?.url} alt="" />
        </div>
        <div className="booking-confirm-total">
          <FormattedMessage
            description="Total Package Price Paid "
            defaultMessage="Total Package Price Paid"
            id="u+QVfG"
          />{" "}
          <span>€{booking?.total}</span>
        </div>
        <div className="booking-confirm-booking">
          <div className="booking-confirm-message">
            <div className="booking-confirm-message-title">
              <FormattedMessage
                description="Dear "
                defaultMessage="Dear "
                id="rkLJUY"
              />{" "}
              {booking?.customer?.name}!
            </div>
            <div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="Thank you for choose Poupas for Holidays for your next trip to"
                  defaultMessage="Thank you for choose Poupas for Holidays for your next trip to"
                  id="tw9E0L"
                />{" "}
                <span className="booking-confirm-message-title">
                  {getCitiesNames(
                    booking?.travelPackage?.destinationCities || [],
                  )}{" "}
                </span>
                .
              </div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="Please note that this only the proof of payment to our office
                  and not the confirmation of your booking. We will send you
                  another email within 24 working hours that will include all the
                  necessary documents for your trip:"
                  defaultMessage="Please note that this only the proof of payment to our office
                  and not the confirmation of your booking. We will send you
                  another email within 24 working hours that will include all the
                  necessary documents for your trip:"
                  id="fdUYtT"
                />

                <span className="booking-confirm-message-title">
                  {getServiceIncludes(
                    booking?.travelPackage?.serviceIncludes || [],
                  )}
                </span>
              </div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="In the meantime we mention below the most important
                  sightseeing’s of the city in order to help you organize your
                  trip better. * You can also see below your booking details.
                  Please confirm that everything is correct in order to avoid not
                  necessary payments for correction on names etc."
                  defaultMessage="In the meantime we mention below the most important
                  sightseeing’s of the city in order to help you organize your
                  trip better. * You can also see below your booking details.
                  Please confirm that everything is correct in order to avoid not
                  necessary payments for correction on names etc."
                  id="i65EcQ"
                />
              </div>

              <div>
                {booking?.travelPackage?.destinationCities.map((dis: ICity) => (
                  <div key={dis.id}>
                    <div className="booking-confirm-message-title">
                      {dis.name}
                    </div>
                    <div
                      key={dis.id}
                      dangerouslySetInnerHTML={{
                        __html: dis?.description || "",
                      }}
                      className="booking-confirm-message-des"
                    />
                  </div>
                ))}
              </div>
              <div>
                {booking?.travelPackage?.policies.map((dis: IPolicy) => (
                  <div key={dis.id}>
                    <div className="booking-confirm-message-title">
                      {dis.name}
                    </div>
                    <div
                      key={dis.id}
                      dangerouslySetInnerHTML={{
                        __html: dis?.description || "",
                      }}
                      className="booking-confirm-message-des"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Passengers passengers={booking?.passengers || []} />
          <Cart
            hotels={booking?.packageHotels || []}
            rooms={booking?.rooms || []}
            loading={false}
            flights={booking?.flights || []}
            isTabletOrMobile={isTabletOrMobile}
            luggages={booking?.luggages || defaultLuggages}
            discount={+(booking?.[discountFieldName] || 0)}
          />
        </div>
      </div>
    </PackageBookingConfirmFrontendWrapper>
  );
};
