import { getFunctions, httpsCallable } from "firebase/functions";
import { ICustomer14860, IQueryConfigArgs } from "../../common/types";
import { getInitialFormName } from "../../common/util/util";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CUSTOMER14860 } from "./firebaseConstants";

const functions = getFunctions();
const createUser = httpsCallable(functions, "createUser");
export const setNewPassword = httpsCallable(functions, "setNewPassword");
export function fetchCustomer14860FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CUSTOMER14860);
  return collectionWithArgs(ref, args || []);
}
export function listenToCustomer14860FromFirestore(id: string) {
  return db.collection(COLLECTION_CUSTOMER14860).doc(id);
}

export async function addCustomer14860ToFirestore(
  doc: Partial<ICustomer14860>,
): Promise<string | null> {
  return new Promise(async (resolve, reject) => {
    try {
      const result: any = await createUser({
        ...doc,
        displayName: `${doc.name} ${doc.surname}`,
        avatar: getInitialFormName(`${doc.name} ${doc.surname}`),
      });
      resolve(result?.data?.uid || null);
    } catch (error) {
      reject(error);
    }
  });
}
export async function checkUserInFirestore(idNumber: string) {
  try {
    const currentCustomer = await db
      .collection(COLLECTION_CUSTOMER14860)
      .doc(idNumber)
      .get();
    if (currentCustomer.exists) {
      sessionStorage.setItem("userId", idNumber);
      await db.collection(COLLECTION_CUSTOMER14860).doc(idNumber).update({
        lastLoginDate: new Date(),
      });
      return currentCustomer.data();
    }
    throw new Error("User do exist");
  } catch (err) {
    throw err;
  }
}

export async function checkUserPoedInFirestore(
  idNumber: string,
  phoneNumber: string,
) {
  try {
    const id = `${idNumber}-${phoneNumber}`;
    const currentCustomer = await db
      .collection(COLLECTION_CUSTOMER14860)
      .doc(id)
      .get();
    if (currentCustomer.exists) {
      sessionStorage.setItem("userId", id);
      const user: any = currentCustomer.data();
      await db.collection(COLLECTION_CUSTOMER14860).doc(id).update({
        lastLoginDate: new Date(),
      });
      return user;
    }
    throw new Error("User do exist");
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    throw err;
  }
}
