import { values } from "lodash";
import { ISelectOption } from "../common/types";

interface IPartnerTypesMap {
  [key: string]: ISelectOption;
}
export const periodType: IPartnerTypesMap = {
  APRIL_1ST: {
    label: "1η Απριλίου",
    value: "APRIL_1ST",
  },
  OCTOBER_1ST: {
    label: "1η Οκτωβρίου",
    value: "OCTOBER_1ST",
  },
  MARCH_25TH: {
    label: "25η Μαρτίου",
    value: "MARCH_25TH",
  },
  OCTOBER_28TH: {
    label: "28η Οκτωβρίου",
    value: "OCTOBER_28TH",
  },
  SPRING: {
    label: "Άνοιξη",
    value: "SPRING",
  },
  GREEN_MONDAY: {
    label: "Καθαρά Δευτέρα",
    value: "GREEN_MONDAY",
  },
  SUMMER: {
    label: "Καλοκαίρι",
    value: "SUMMER",
  },
  CARNIVAL: {
    label: "Καρναβάλια",
    value: "CARNIVAL",
  },
  FLOOD_DAY: {
    label: "Κατακλυσμός",
    value: "FLOOD_DAY",
  },
  EASTER: {
    label: "Πάσχα",
    value: "EASTER",
  },
  NEW_YEAR_EVE: {
    label: "Πρωτοχρονιά",
    value: "NEW_YEAR_EVE",
  },
  AUTUMN: {
    label: "Φθινόπωρο",
    value: "AUTUMN",
  },
  WINTER: {
    label: "Χειμώνας",
    value: "WINTER",
  },
  CHRISTMAS: {
    label: "Χριστούγεννα",
    value: "CHRISTMAS",
  },
};

export const periodTypeOptions = values(periodType);
