/* eslint-disable no-console */
import React from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FilePdfOutlined } from "@ant-design/icons";
import { ILuggage } from "../../../common/typings";
import { Form } from "../../atoms/Form/Form";
import {
  BookingComment,
  BookingContactInformation,
  BookingPassenger,
  Cart,
  CartTotalBookingBtn,
  InputCheckBoxField,
  ItemNotFound,
  Loader,
  PackageBookingFrontEndWrapper,
} from "../../molecules";
import {
  listenToBookingFromFirestore,
  updateBookingInFirestore,
} from "../../../firestore/firestoreService";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { jccConvertAmount } from "../../../common/util/util";
import { Checkbox } from "../../atoms";
import { axiosGet, axiosPost } from "../../../config/axios";
import { IBookingPackage } from "../../../common/types";
const partnerNameGr = process.env.REACT_APP_PARTNER_NAME_GR;
const partnerId = process.env.REACT_APP_SITE_ID || "";
const VIVA_PAYMENT_BASED_URL =
  "https://us-central1-poupas-9e426.cloudfunctions.net/vivaPackagePayment";
export interface IPackageBookingFrontEndProps {
  bookingId: string;
}
const defaultLuggages: ILuggage = {
  numberOf10KGLuggage: 0,
  numberOf20KGLuggage: 0,
  price20KGLuggage: 0,
  price10KGLuggage: 0,
};
export const PackageBooking: React.FC<IPackageBookingFrontEndProps> = ({
  bookingId,
}) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<any>();
  const history = useNavigate();
  const [loadingPayment, setLoading] = React.useState(false);
  const [errorPayment, setError] = React.useState("");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  // const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { loading, error } = useSelector((state: any) => state.async);
  const { selectedCustomer14860 } = useSelector(
    (state: any) => state.customers,
  );
  const [showCart, setShowCart] = React.useState(true);
  const [form] = Form.useForm();
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  const handleOnFinish = (type: string) => async () => {
    try {
      const values: IBookingPackage = await form.validateFields();
      const passengers = values.passengers.map((passenger) => ({
        ...passenger,
        dateOfBirth: moment(passenger.dateOfBirth).format("YYYY-MM-DD"),
      }));
      await updateBookingInFirestore(bookingId, {
        ...selectedBooking,
        id: bookingId,
        status: selectedBooking.onRequest ? "ON_REQUEST" : "IN_PROGRESS",
        comment: values.comment || "",
        customer: values.customer,
        customerId: selectedCustomer14860?.id || "",
        passengers,
        paymentType: type,
      });
      if (type === "PARTIAL_PAYMENT") {
        await handlePayment("PARTIAL_PAYMENT");
      } else if (type === "FULL_PAYMENT") {
        await handlePayment("FULL_PAYMENT");
      } else {
        history(`/package-booking-on-request-confirm/${bookingId}`);
      }
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: bookingId !== "new",
  });
  const handlePayment = async (type: string) => {
    try {
      setLoading(true);
      const { data } = await axiosGet(`${VIVA_PAYMENT_BASED_URL}/get-token`);
      if (data.token) {
        const { data: dataApi, status } = await axiosPost(
          `${VIVA_PAYMENT_BASED_URL}/package-booking-create-transaction`,
          { token: data.token, bookingId, type },
        );
        if (status === 200) {
          window.location.href = dataApi.checkOutUrl;
        }
      } else {
        throw new Error(`No Token`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);
  // React.useEffect(() => {
  //   if (!selectedBooking) {
  //     history(-1);
  //   }
  // }, [selectedBooking]);
  if (loading && !selectedBooking?.signature) {
    return <Loader color="success" title="Loading" />;
  }
  if (error) {
    return (
      <ItemNotFound
        redirectTo=""
        redirectBtnText=""
        itemType="Booking"
        itemId={bookingId}
      />
    );
  }
  return (
    <PackageBookingFrontEndWrapper>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedBooking,
          customer: {
            name: selectedCustomer14860?.name || "",
            email: selectedCustomer14860?.emailAddress || "",
            phone: selectedCustomer14860?.phone || "",
            id: "",
          },
        }}
      >
        <div className="package-view-frontend-body">
          <div className="package-view-frontend-left">
            {isTabletOrMobile && (
              <Cart
                discount={+(selectedBooking?.discount || 0)}
                hotels={selectedBooking?.packageHotels || []}
                rooms={selectedBooking?.rooms || []}
                loading={false}
                flights={selectedBooking?.flights || []}
                onShowCart={handelShowCart}
                isTabletOrMobile={isTabletOrMobile}
                luggages={selectedBooking?.luggages || defaultLuggages}
                noScrolling
              />
            )}
            <BookingContactInformation partnerId={selectedBooking?.partnerId} />
            <Form.List name="passengers">
              {(fields, values, ff) =>
                fields.map(({ key, name, ...restField }, index) => (
                  <BookingPassenger
                    name={name}
                    key={key}
                    departureDated={selectedBooking.travelPackage.departureDate}
                    type={selectedBooking.passengers[name]?.type || "ADULT"}
                    roomName={selectedBooking.passengers[name]?.roomName || ""}
                  />
                ))
              }
            </Form.List>
            <BookingComment />
            <div style={{ padding: "0px 10px" }}>
              {partnerId !== "POUPAS" && (
                <InputCheckBoxField
                  label=""
                  name="acceptPasydy"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  options={[
                    {
                      label: `Επιβεβαιώνω πως τα στοιχεία κράτησης είναι αληθή και ταυτοποιούνται με τα στοιχεία ενεργού μέλους της ${partnerNameGr}. Σε αντίθετη περίπτωση η Poupas for Holidays διατηρεί το δικαίωμα επιπλέον χρέωσης ή ακόμη κι ακύρωσης της κράτησης σας.`,
                      value: "YES",
                    },
                  ]}
                />
              )}
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Θα πρέπει να Συμφωνήσετε με το πιο πάνω για να ολοκληρωθεί η κράτηση",
                            ),
                          ),
                  },
                ]}
              >
                <Checkbox>
                  <FormattedMessage
                    description="I have read the"
                    defaultMessage="I have read the"
                    id="zAlta4"
                  />{" "}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage
                      description="Terms and Conditions"
                      defaultMessage="Terms and Conditions"
                      id="T9dfsO"
                    />
                  </Link>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="readRoles"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Θα πρέπει να Συμφωνήσετε με το πιο πάνω για να ολοκληρωθεί η κράτηση",
                            ),
                          ),
                  },
                ]}
              >
                <Checkbox>
                  Γνωρίζω κι Αποδέχομαι τον «συνδεδεμένο ταξιδιωτικό
                  διακανονισμό» του περί των Οργανωμένων Ταξιδιών και
                  Συνδεδεμένων Ταξιδιωτικών Διακανονισμών Νόμου του 2017.
                  <Link
                    to="/assets/important-roles.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Πατήστε εδώ για να διαβάσετε. <FilePdfOutlined />
                  </Link>
                </Checkbox>
              </Form.Item>
            </div>
            <CartTotalBookingBtn
              submit={handleOnFinish}
              hotels={selectedBooking?.packageHotels || []}
              rooms={selectedBooking?.rooms || []}
              loading={loading}
              luggages={selectedBooking?.luggages}
              discount={selectedBooking?.discount}
              onRequest={selectedBooking?.onRequest || false}
              totalPartialPayment={selectedBooking?.totalPartialPayment}
              cancellationPolicy={selectedBooking?.cancellationPolicy}
              allowPartialPayment={
                selectedBooking?.allowPartialPayment &&
                !(moment(selectedBooking?.refundableDate) < moment())
              }
              refundableDate={moment(selectedBooking?.refundableDate).format(
                "DD/MM/YYYY",
              )}
              isTabletOrMobile={isTabletOrMobile}
              totalNonRefundableAmount={
                selectedBooking?.totalNonRefundableAmount
              }
              bookingId={bookingId}
              customerId={selectedCustomer14860?.id || ""}
              totalPrice={selectedBooking?.totalPrice || 0}
              couponCode={selectedBooking?.couponCode || ""}
              couponValue={+(selectedBooking?.couponDiscount || 0)}
              partnerId={partnerId}
              loadingPayment={loadingPayment}
              errorPayment={errorPayment}
              // discountOffer={selectedBooking?.discountOffer}
            />
            <div style={{ height: 200 }}></div>
          </div>

          <div
            className={classNames("package-view-frontend-right", {
              hide: showCart,
            })}
          >
            {!isTabletOrMobile && (
              <Cart
                hotels={selectedBooking?.packageHotels || []}
                rooms={selectedBooking?.rooms || []}
                loading={false}
                flights={selectedBooking?.flights || []}
                onShowCart={handelShowCart}
                isTabletOrMobile={isTabletOrMobile}
                discount={+(selectedBooking?.discount || 0)}
                luggages={selectedBooking?.luggages || defaultLuggages}
              />
            )}
          </div>
        </div>
      </Form>

      <form
        method="post"
        name="paymentForm"
        id="paymentForm"
        ref={(ref) => (formRef.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(selectedBooking?.total || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={selectedBooking?.signature || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
    </PackageBookingFrontEndWrapper>
  );
};
