import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_PACKAGES,
  COLLECTION_PACKAGE_POLICY,
} from "./firebaseConstants";

export function fetchPackagePolicyFromFirestore(
  packageId: string,
  args?: IQueryConfigArgs[],
) {
  const ref = db
    .collection(COLLECTION_PACKAGES)
    .doc(packageId)
    .collection(COLLECTION_PACKAGE_POLICY);
  return collectionWithArgs(ref, args || []);
}
