/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { HomePageWrapper } from "../HotelPage/HotelPage.style";

const CruisePage: React.FC = () => {
  console.log("loading");
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <iframe
                  src="https://chb.cruisec.net/test?aid=210375"
                  frameBorder="0"
                  style={{ width: "100%", height: 800 }}
                ></iframe>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <img src="/assets/gov_logos.jpg" alt="logos" />
        </div>
      </div>
    </HomePageWrapper>
  );
};

export default CruisePage;
