/* eslint-disable no-console */
import React from "react";
import { Helmet } from "react-helmet";
import { findIndex, get, some } from "lodash";
import { useNavigate } from "react-router";
import { notification } from "antd";
import cuid from "cuid";
import moment from "moment";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { DoubleRightOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import firebase from "../../../config/firebase";
import { IPackageHotelPublic, IRoomInput } from "../../../common/typings";
import {
  Cart,
  ItemNotFound,
  Loader,
  PackageDetail,
  PackageFlights,
  PackageHotels,
  PackageInfo,
  PackageLuggage,
  PackageViewFrontEndWrapper,
  TravelerSelectors,
} from "../../molecules";
import {
  addBookingToFirestore,
  fetchCity83431FromFirestore,
  fetchPackagePolicyFromFirestore,
  getBookingTotal,
} from "../../../firestore/firestoreService";
import { convertRoomsToPassenger } from "../../../common/util/util";
import { Button } from "../../atoms";
import { ICity83431, IPackage, IPolicy } from "../../../common/types";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";

export interface IPackageViewFrontEndProps {
  travelPackage: IPackage;
  packageId: string;
}
type DiscountType = "pasydyDiscount" | "poedDiscount";
const partnerId = process.env.REACT_APP_SITE_ID || "";
const discountFieldName = process.env.REACT_APP_SITE_DISCOUNT as DiscountType;
const vivaSourceCode = process.env.REACT_APP_VIVA_PACKAGE_SOURCE_CODE || "";
const roomTypes: { [key: string]: number } = {
  single: 1,
  double: 2,
  triple: 3,
  quadruple: 4,
};
export const PackageDetails: React.FC<IPackageViewFrontEndProps> = ({
  travelPackage,
  packageId,
}) => {
  const [hotelsSelection, setHotelSelection] = React.useState<
    IPackageHotelPublic[]
  >([]);
  const history = useNavigate();
  const { loading: loadingData, error } = useSelector(
    (state: any) => state.async,
  );
  const { selectedCustomer14860 } = useSelector(
    (state: any) => state.customers,
  );
  const dispatch = useDispatch();
  const [discount] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [policies, setPolicies] = React.useState<IPolicy[]>([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [numberOf10KGLuggage, setNumberOf10KGLuggage] = React.useState(0);
  const [numberOf20KGLuggage, setNumberOf20KGLuggage] = React.useState(0);
  const [showCart, setShowCart] = React.useState(true);
  const [cities, setCities] = React.useState<ICity83431[]>([]);
  const [rooms, setRooms] = React.useState<IRoomInput[]>([
    { roomType: "double", numberChilds: 0 },
  ]);
  const handelHotelsSelection = (hotel: IPackageHotelPublic) => {
    const index = findIndex(hotelsSelection, ["cityId", hotel.cityId]);
    if (index === -1) {
      setHotelSelection([...hotelsSelection, hotel]);
    } else {
      setHotelSelection([
        ...hotelsSelection.filter((h) => h.cityId !== hotel.cityId),
        hotel,
      ]);
    }
  };
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  useFirestoreCollection({
    query: () => fetchPackagePolicyFromFirestore(packageId),
    data: setPolicies,
    deps: [dispatch, packageId],
    local: true,
    shouldExecute: !!packageId,
  });

  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: firebase.firestore.FieldPath.documentId(),
          opr: "in",
          value: travelPackage?.destinations?.map(
            (destination) => destination.cityId,
          ), // Array of actual Firestore doc IDs
        },
      ]),
    data: setCities,
    deps: [dispatch, packageId, travelPackage],
    local: true,
    shouldExecute: !!packageId && !!travelPackage,
  });
  const getNumberOfAdultsChildsDisplay = (roomsView: IRoomInput[]) => {
    const numberOfAdults = roomsView.reduce(
      (sum, n: IRoomInput) => sum + Number(roomTypes[n.roomType]),
      0,
    );
    const numberOfChilds = roomsView.reduce(
      (sum, n: IRoomInput) => sum + Number(n.numberChilds),
      0,
    );
    return `${roomsView.length} Δωμάτιο/α (${numberOfAdults} Ενήλικας/ες) ${
      numberOfChilds > 0 ? `${numberOfChilds} Παιδί/ά` : ""
    }`;
  };
  const getNumberOfAdultsChild = (roomsView: IRoomInput[]) => {
    const numberOfAdults = roomsView.reduce(
      (sum, n: IRoomInput) => sum + Number(roomTypes[n.roomType]),
      0,
    );
    const numberOfChilds = roomsView.reduce(
      (sum, n: IRoomInput) => sum + Number(n.numberChilds),
      0,
    );
    return numberOfAdults + numberOfChilds;
  };
  const handleSubmit = async () => {
    const roomsWithIds = rooms.map((room) => ({ ...room, id: cuid() }));
    const passengers = convertRoomsToPassenger(roomsWithIds);
    try {
      setLoading(true);
      const booking = await addBookingToFirestore({
        travelPackageId: travelPackage.id,
        travelPackage: {
          departureDate: moment(travelPackage.departureDate).format(
            "YYYY-MM-DD",
          ),
          returnDate: moment(travelPackage.returnDate).format("YYYY-MM-DD"),
          departureCity: travelPackage.departureCity,
          departureCityId: travelPackage.departureCityId,
          serviceInclude: travelPackage.serviceInclude,
          name: travelPackage.name || "",
          description: travelPackage.description || "",
          categories: travelPackage.categories,
          categoryIds: travelPackage.categoryIds,
          destinationIds: travelPackage.destinationIds || [],
          image: get(travelPackage, "images[0]"),
          destinationCities: cities,
          policies: policies || [],
        },
        packageHotels: hotelsSelection,
        flights: travelPackage.flights || [],
        passengers,
        luggages: {
          numberOf10KGLuggage: numberOf10KGLuggage || 0,
          numberOf20KGLuggage: numberOf20KGLuggage || 0,
          price20KGLuggage: Number(travelPackage.price20KGLuggage || 0),
          price10KGLuggage: Number(travelPackage.price10KGLuggage || 0),
        },
        rooms: roomsWithIds,
        onRequest: travelPackage.onRequest,
        cancellationPolicy: travelPackage.cancellationPolicy,
        allowPartialPayment:
          travelPackage.allowPartialPayment &&
          !some(
            hotelsSelection,
            (v: IPackageHotelPublic) =>
              v.cancellationPolicy === "NON_REFUNDABLE",
          ),
        partialPayment: +travelPackage.partialPaymentPercent,
        fullPaymentDueDate: travelPackage.fullPaymentDueDate,
        refundableDate: travelPackage.refundableDate,
        nonRefundableAmount: +travelPackage.nonRefundableAmountPercent,
        partnerDiscount: +(travelPackage?.[discountFieldName] || 0),
        partnerId,
        customer: {
          name: selectedCustomer14860?.name || "",
          email: selectedCustomer14860?.emailAddress || "",
          phone: selectedCustomer14860?.phone || "",
          id: "",
        },
        vivaSourceCode,
        // discountOffer: +(discount || 0),
        agentComment: discount ? "Black Friday" : "",
      });
      setLoading(false);
      history(`/package-booking/${booking}`);
    } catch (errorInfo: any) {
      setLoading(false);
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  // React.useEffect(() => {
  //   const selectedHotels: any[] =
  //     chain(travelPackage.hotels)
  //       .groupBy("cityId")
  //       .map((hotel: any) => minBy(hotel, "doubleRoomPricePublic"))
  //       .value() || [];
  //   setHotelSelection(selectedHotels);
  // }, [travelPackage]);
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);
  if (loadingData) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="City"
        itemId={"sd"}
      />
    );
  }
  return (
    <PackageViewFrontEndWrapper>
      <div className="package-view-frontend-body">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{travelPackage.name}</title>
          <meta property="og:image" content={"dsdsds/sdsdsds"} />
          <meta property="og:type" content="article wewewew" />
          <meta name="description" content="Helmet application" />
        </Helmet>
        <div className="package-view-frontend-left">
          {discount && (
            <div className="package-view-frontend-booking-details-discount-text">
              Προσφορά Μόνο για τη Black Friday! - 10%{" "}
            </div>
          )}
          <div className="package-view-frontend-left-info">
            <PackageInfo
              title={travelPackage.name}
              departureCity={travelPackage.departureCity || {}}
              departureDate={travelPackage.departureDate || ""}
              returnDate={travelPackage.returnDate || ""}
              serviceInclude={travelPackage.serviceInclude || []}
              categories={travelPackage?.categories || []}
              partner={travelPackage?.partnerId}
            />
          </div>
          <PackageDetail
            description={travelPackage.description || ""}
            images={travelPackage.images || []}
            policies={policies}
            cities={cities}
          />
          <TravelerSelectors
            setRooms={setRooms}
            rooms={rooms}
            selectedHotels={setHotelSelection}
            isMobile={isMobile}
          />
          {travelPackage.serviceInclude?.includes("HOTELS") && (
            <PackageHotels
              destinations={travelPackage.destinations || []}
              hotels={travelPackage.hotels || []}
              selectHotel={handelHotelsSelection}
              selectedHotels={hotelsSelection}
              isTabletOrMobile={isTabletOrMobile}
              isMobile={isMobile}
              selectedRooms={rooms}
              travelPackage={travelPackage}
              discount={
                +(travelPackage?.[discountFieldName as keyof IPackage] || 0)
              }
            />
          )}
          {((travelPackage?.price10KGLuggage ||
            travelPackage?.price20KGLuggage) && (
            <PackageLuggage
              passengers={getNumberOfAdultsChild(rooms)}
              price20KGLuggage={travelPackage?.price20KGLuggage || 0}
              price10KGLuggage={travelPackage?.price10KGLuggage || 0}
              setNumberOf10KGLuggage={setNumberOf10KGLuggage}
              setNumberOf20KGLuggage={setNumberOf20KGLuggage}
              isMobile={isMobile}
            />
          )) ||
            ""}
          {travelPackage.serviceInclude?.includes("FLIGHTS") && (
            <PackageFlights
              flights={travelPackage.flights || []}
              isMobile={isMobile}
            />
          )}
          {/* {travelPackage.serviceInclude?.includes("CRUISES") && (
            <PackageCruises travelPackage={travelPackage} />
          )}
          {travelPackage.serviceInclude?.includes("TRAINS") && (
            <PackageTrains travelPackage={travelPackage} />
          )}
          {travelPackage.serviceInclude?.includes("TOURS") && (
            <PackageTours travelPackage={travelPackage} />
          )} */}
          {/* {travelPackage.serviceInclude?.includes("TRANSFERS") && (
            <PackageTransfers travelPackage={travelPackage} />
          )} */}

          {isTabletOrMobile && (
            <div style={{ padding: "0 10px" }}>
              <Button
                className="package-view-frontend-booking-details-btn-btn"
                onClick={handelShowCart}
              >
                <FormattedMessage
                  description="View Your Selection"
                  defaultMessage="View Your Selection"
                  id="9TOlZF"
                />{" "}
                <DoubleRightOutlined />
              </Button>
            </div>
          )}
          <div style={{ height: 200 }}></div>
          {isTabletOrMobile && (
            <div className="package-view-frontend-booking-details">
              <div className="package-view-frontend-booking-details-btn-label">
                {getNumberOfAdultsChildsDisplay(rooms)}
              </div>
              {hotelsSelection?.length > 0 && (
                <div className="package-view-frontend-booking-details-btn-label">
                  <FormattedMessage
                    description="Your Package Total Price is"
                    defaultMessage="Your Package Total Price is"
                    id="5K1U8w"
                  />{" "}
                  <span>
                    €
                    {getBookingTotal(
                      hotelsSelection,
                      rooms,
                      {
                        numberOf10KGLuggage: numberOf10KGLuggage || 0,
                        numberOf20KGLuggage: numberOf20KGLuggage || 0,
                        price20KGLuggage: travelPackage.price20KGLuggage || 0,
                        price10KGLuggage: travelPackage.price10KGLuggage || 0,
                      },
                      +(travelPackage?.[discountFieldName] || 0),
                    )}
                  </span>
                  {/* {discount && (
                    <div className="package-view-frontend-booking-details-discount-text">
                      Προσφορά Μόνο για τη Black Friday! - 10%{" "}
                      <span
                        className="cart-total-total-price"
                        style={{ color: "#fff" }}
                      >
                        €
                        {getBookingTotal(
                          hotelsSelection,
                          rooms,
                          {
                            numberOf10KGLuggage: numberOf10KGLuggage || 0,
                            numberOf20KGLuggage: numberOf20KGLuggage || 0,
                            price20KGLuggage:
                              travelPackage.price20KGLuggage || 0,
                            price10KGLuggage:
                              travelPackage.price10KGLuggage || 0,
                          },
                          +(travelPackage?.[discountFieldName] || 0),
                          +discount,
                        )}
                      </span>
                    </div>
                  )} */}
                </div>
              )}

              {hotelsSelection?.length === 0 && (
                <div style={{ fontWeight: 700 }}>
                  Παρακαλώ επιλέξτε το Ξενοδοχείο της προτίμησης σας για να
                  προχωρήσετε με κράτηση.
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames("package-view-frontend-right", {
            hide: showCart,
          })}
        >
          <Cart
            hotels={hotelsSelection}
            rooms={rooms}
            submit={handleSubmit}
            loading={loading}
            flights={travelPackage.flights || []}
            onShowCart={handelShowCart}
            isTabletOrMobile={isTabletOrMobile}
            discount={+(travelPackage?.[discountFieldName] || 0)}
            luggages={{
              numberOf10KGLuggage: numberOf10KGLuggage || 0,
              numberOf20KGLuggage: numberOf20KGLuggage || 0,
              price20KGLuggage: +(travelPackage.price20KGLuggage || 0),
              price10KGLuggage: +(travelPackage.price10KGLuggage || 0),
            }}
            discountOffer={discount ? +discount : undefined}
          />
        </div>
      </div>
    </PackageViewFrontEndWrapper>
  );
};
