import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .contact-us {
    &-address {
      background-color: #eee;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 50px;
      padding-left: 50px;
      flex: 500px;
      max-width: 500px;
      &-title {
        font-size: 2.1em;
        font-weight: 700;
        font-family: Montserrat, sans-serif, "Open Sans";
        margin-bottom: 20px;
        color: #38a7cd;
      }
      &-sub-title {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 20px;
      }
      &-divider {
        margin-top: 20px;
        margin-bottom: 50px;
        border-top: 1px solid #dbe4eb;
      }
      &-text {
        font-size: 16px;
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        .anticon {
          font-size: 18px;
          color: #38a7cd;
        }
      }
    }
    &-form {
      background-color: #fff;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      padding-top: 40px;
    }
  }
`;

export const ContactUsFormWrapper = styled.div`
  margin: 20px 20px;
  min-width: 500px;
  margin: 20px auto;
  background-color: #dbe4eb;
  padding: 20px;
  border: 1px solid #dbe4eb;
  @media (max-width: 500px) {
    width: 100%;
    min-width: 100%;
  }
  .request-form {
    text-align: left;
    &-title {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 20px;
    }
    &-sub-title {
      font-size: 14px;
      margin-bottom: 20px;
    }
    &-button {
      border-radius: 3px;
      background: transparent;
      -webkit-text-decoration: none;
      text-decoration: none;
      padding: 10px 12px;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      border: 1px solid #8db906;
      background-color: #8db906;
      color: #fff !important;
      margin: 6px 0px;
      font-weight: 700;
      transition: all 0.4s ease-in-out;
      height: 50px;
      &:hover {
        opacity: 1;
        background-color: darken(#8db906, 10%);
        border-color: darken(#8db906, 10%);
        transition: all 0.4s ease-in-out;
      }
    }
  }
`;
