import styled from "styled-components";

// theme is now fully typed
export const FooterWrapper = styled.div`
  display: flex;
  padding: 25px 23px;
  background-color: #f2f2f2;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin-top: 50px;
  flex-direction: column;
  @media (max-width: 500px) {
    flex-direction: column;
  }

  .footer {
    &-logos {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      font-size: 12px;
      color: #888888;
    }
    &-social {
      a {
        font-size: 21px;
        color: #188dcc;
      }
    }
    &-mobile-icon {
      position: absolute;
      right: 10px;
      top: 6px;
    }
    &-contact-us {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 10px;
      margin-bottom: 10px;
      gap: 5px;
      &-icon {
        font-size: 21px;
        color: #188dcc;
      }
      &-text {
        color: #888888;
        margin-right: 20px;
        text-align: center;
        > span {
          font-weight: 700;
          padding-right: 10px;
        }
      }
      &-title {
        font-size: 1.6em;
        font-weight: 700;
        margin-bottom: 10px;
        color: #188dcc;
      }
    }
  }
`;
