/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { MobileMenuWrapper } from "./MobileMenu.style";
interface IMobileMenuProps {
  showMenu: boolean;
  setShowMenu: (e: boolean) => void;
}

const partnerEmail =
  process.env.REACT_APP_SITE_EMAIL || "reservations@poupas.com.cy";
export const MobileMenu: React.FC<IMobileMenuProps> = ({
  showMenu,
  setShowMenu,
}) => {
  const handleShowMobileMenu = () => {
    setShowMenu(false);
  };
  return (
    <MobileMenuWrapper showMenu={showMenu}>
      <div className="mobile-menu-title">
        <FormattedMessage
          description="Plan Your Holidays"
          defaultMessage="Plan Your Holidays"
          id="yCqdwg"
        />
      </div>

      <div className="mobile-menu-items">
        <div className="mobile-menu-sub-title">
          <FormattedMessage
            description="I 'M SEARCHING FOR"
            defaultMessage="I 'M SEARCHING FOR"
            id="RHBL9W"
          />
        </div>
        <Link
          to={"/"}
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Packages"
            defaultMessage="Packages"
            id="46PJ2T"
          />
        </Link>

        <Link
          to="/hotels-home"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          Ξενοδοχεία Κύπρου
        </Link>
        <Link
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
          to="/cruises"
        >
          Κρουαζιέρες
        </Link>
      </div>
      <div className="mobile-menu-contact-us">
        <Link to="/contact-us" className="mobile-menu-contact-us-title">
          Επικοινωνήστε μαζί μας
        </Link>
        <div className="mobile-menu-contact-us-text">
          <span>Τηλεφωνικός:</span>
          7008 7003 ή +35722252508
        </div>
        <div className="mobile-menu-contact-us-text">
          <span>Email:</span>
          <Link style={{ color: "#fff" }} to={`mailto:${partnerEmail}`}>
            {partnerEmail}
          </Link>
        </div>
        <div className="mobile-menu-contact-us-text">
          {/* <span>Social Media:</span> */}
          <Space size={20}>
            <a
              href="https://www.facebook.com/poupasforholidays"
              target="_blank"
              rel="noreferrer"
              className="mobile-menu-contact-us-icon"
            >
              {" "}
              <FacebookOutlined />
            </a>
            <a
              href="https://www.instagram.com/poupasforholidays"
              target="_blank"
              rel="noreferrer"
              className="mobile-menu-contact-us-icon"
            >
              {" "}
              <InstagramOutlined />
            </a>
          </Space>
        </div>

        {/* {!authenticated ? (
          <Button className="mobile-menu-btn" onClick={handleLogin}>
            {" "}
            <FormattedMessage
              description="Login"
              defaultMessage="Login"
              id="gdzUOH"
            />
          </Button>
        ) : (
          <Button className="mobile-menu-btn" onClick={handleLogout}>
            {" "}
            <FormattedMessage
              description="Logout"
              defaultMessage="Logout"
              id="LAaOhU"
            />
          </Button>
        )} */}
      </div>
    </MobileMenuWrapper>
  );
};
