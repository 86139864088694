import styled from "styled-components";
export const LoginLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #37a7cd;
  padding: 10px;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  .header-pasydy-logo {
    height: 70px;
  }
  .header-pasydy-logo-name {
    color: #fff;
  }
`;

export const LoginNewUserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 10px;
  .loginNewUser {
    &-action {
      flex: 1;
    }
    &-link {
      text-align: center;
    }
    &-or {
      display: flex;
      align-items: center;
      padding-top: 18px;
    }
  }
`;
