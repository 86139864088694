/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { Col, Row } from "antd";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { orderBy } from "lodash";
import {
  fetchCategory81497FromFirestore,
  fetchCity83431FromFirestore,
  fetchPartnerBannerFromFirestore,
  listenToPartnerFromFirestore,
} from "../../App/firestore/firestoreService";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { listenToCategories } from "../../App/redux/data/category/categoryActions";
import { Footer, PackageSearchBox } from "../../App/components/molecules";
import { listenToCities } from "../../App/redux/data/city/cityActions";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { IPartner, IPartnerBanner } from "../../App/common/types";
import { Loader } from "../../App/components/molecules/Loader/Loader";
import { HomePageWrapper, MyGalleryWrapper } from "./HotelPage.style";

const partnerId = process.env.REACT_APP_SITE_ID || "POUPAS";
const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.async);
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const [localLoading, setLoading] = React.useState(false);
  const [partner, setPartner] = React.useState<IPartner>();
  const [banners, setBanners] = React.useState<IPartnerBanner[]>([]);
  userFirestoreDoc({
    query: () => listenToPartnerFromFirestore(partnerId),
    data: setPartner,
    shouldExecute: !!partnerId,
    deps: [dispatch, partnerId],
    local: true,
    localSetLoader: setLoading,
  });
  useFirestoreCollection({
    query: () =>
      fetchPartnerBannerFromFirestore(partnerId, [
        {
          type: "filter",
          name: "display",
          opr: "==",
          value: "HOME_PAGE",
        },
      ]),
    data: setBanners,
    shouldExecute: !!partnerId,
    local: true,
    deps: [dispatch, partnerId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: "hasPackages",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToCities,
    deps: [],
  });
  useFirestoreCollection({
    query: () =>
      fetchCategory81497FromFirestore([
        {
          type: "filter",
          name: "hasPackages",
          opr: "==",
          value: true,
        },
      ]),
    data: (data: any) => listenToCategories(orderBy(data, ["name"])),
    deps: [],
  });

  if (loading || localLoading) return <Loader color="#fff" />;
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <div
                  className="bgimg bg-hotel"
                  style={
                    !isMobile
                      ? {
                          background: `url(${partner?.headerBanner?.[0]?.url || "/assets/images/Background-Poupas-for-Holidays.jpg"})
        right top/cover fixed`,
                        }
                      : {}
                  }
                ></div>
                <div className="heading">
                  <div className="flights-search-header">
                    <span
                      className="blue-color"
                      style={{
                        color: isMobile ? "#fff" : partner?.headerTitleColor,
                      }}
                    >
                      {partner?.headerTitle}
                    </span>
                  </div>
                  <div>
                    <PackageSearchBox cities={cities} categories={categories} />
                  </div>
                </div>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>

        {orderBy(banners, ["orderId"])?.map((banner: any) => (
          <div className="section" key={banner.id}>
            <div className="section-heading">
              <h2 className="blue-color">{banner.title}</h2>
              {banner.subtitle && <div>{banner.subtitle}</div>}
            </div>
            <MyGalleryWrapper>
              <Row gutter={10}>
                {orderBy(banner?.banners, ["orderId"])?.map((bannerImage) => (
                  <Col span={12} xs={24} sm={12} key={bannerImage.id}>
                    <Link to={bannerImage.url || ""}>
                      <img
                        src={bannerImage.image?.[0]?.url || ""}
                        alt={bannerImage?.title || ""}
                        style={{
                          width: isMobile ? 300 : 400,
                          height: isMobile ? 200 : 300,
                        }}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </MyGalleryWrapper>
          </div>
        ))}
        <Footer isMobile={false} />
      </div>
    </HomePageWrapper>
  );
};

export default HomePage;
