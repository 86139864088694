import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_PARTNER,
  COLLECTION_PARTNER_BANNER,
} from "./firebaseConstants";

export function fetchPartnerBannerFromFirestore(
  partnerId: string,
  args?: IQueryConfigArgs[],
) {
  const ref = db
    .collection(COLLECTION_PARTNER)
    .doc(partnerId)
    .collection(COLLECTION_PARTNER_BANNER);
  return collectionWithArgs(ref, args || []);
}
export function listenToPartnerBannerFromFirestore(
  partnerId: string,
  id: string,
) {
  return db
    .collection(COLLECTION_PARTNER)
    .doc(partnerId)
    .collection(COLLECTION_PARTNER_BANNER)
    .doc(id);
}
