/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from "react";
import {
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import {
  ContactUsWrapper,
  ContactUsForm,
} from "../../App/components/molecules";
import { Space } from "../../App/components/atoms";
import { Footer } from "../../App/components/molecules/Footer/Footer";

const ContactUsPage: React.FC = () => {
  console.log("test");
  return (
    <ContactUsWrapper>
      <div className="contact-us-form">
        <div className="contact-us-address-title"> Επικοινωνήστε μαζί μας </div>
        <ContactUsForm />
        <div className="contact-us-address-divider">
          <div className="contact-us-address-sub-title">
            {" "}
            Γενικές πληροφορίες
          </div>

          <Space
            size={20}
            style={{ flexWrap: "wrap", justifyContent: "center" }}
          >
            <div className="contact-us-address-text">
              <PhoneOutlined /> 7008 7003
            </div>
            <div className="contact-us-address-text">
              <PhoneOutlined /> +35722252508
            </div>
            <div className="contact-us-address-text">
              <MailOutlined /> reservations@poupas.com.cy
            </div>
            <div className="contact-us-address-text">
              <PushpinOutlined />
              Ηρακλέους 27, Στρόβολος 2040
            </div>
          </Space>
        </div>
      </div>
      <Footer isMobile={false} />
    </ContactUsWrapper>
  );
};

export default ContactUsPage;
