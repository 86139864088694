/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { HotelSearchBox } from "../../molecules";
import { fetchCity83431FromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IProps {
  params: {
    adultNumbers?: number;
    childrenNumber?: number;
    infant?: number;
    city?: string;
    period?: string;
  };
}
export const HotelsSearchModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [loading] = React.useState(false);

  const [cities, setCities] = React.useState([]);
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "filter",
          name: "countryCode",
          opr: "==",
          value: "CY",
        },
      ]),
    data: setCities,
    local: true,
    deps: [dispatch],
  });

  const handleOnClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper
      {...props}
      loading={loading}
      footer={false}
      maskClosable
      bodyStyle={{ padding: 0 }}
      width={500}
      onCancel={handleOnClose}
    >
      <HotelSearchBox
        cities={cities}
        callBack={handleOnClose}
        adultNumbers={props.params?.adultNumbers}
        childrenNumber={props.params?.childrenNumber}
        infant={props.params?.infant}
        city={props.params?.city}
        period={props.params?.period}
      />
    </ModalWrapper>
  );
};
