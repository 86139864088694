import React from "react";
import { PackageBookingConfirmFrontend } from "../../App/components/organisms";
interface IProps {
  onRequest?: boolean;
}
const PackageBookingConfirmation: React.FC<IProps> = ({ onRequest }) => (
  <PackageBookingConfirmFrontend onRequest={onRequest} />
);

export default PackageBookingConfirmation;
