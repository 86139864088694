/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter } from "react-router-dom";
import { Loader, Header, MobileMenu } from "../components/molecules";
import ModalManager from "../common/modals/ModalManager";
import { openModal } from "../redux/data/modals/modalReducer";
import AppRouter from "./AppRouter";
import { AppWrapper } from "./App.style";
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const partnerId = process.env.REACT_APP_SITE_ID!;

const partnerLoginForm: any = {
  POED: "LoginFormPoed",
  PASYDY: "LoginForm",
};
const App: React.FC = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const [showMenu, setShowMenu] = React.useState(false);
  const { initialized } = useSelector((state: any) => state.async);
  const { selectedCustomer14860 } = useSelector(
    (state: any) => state.customers,
  );
  React.useEffect(() => {
    if (!userId && ["PASYDY", "POED"].includes(partnerId)) {
      console.log(selectedCustomer14860, userId);
      // if (!userId) {
      dispatch(
        openModal({
          modalType: partnerLoginForm[partnerId] || "LoginForm",
          modalProps: { title: "Σύνδεση" },
        }),
      );
    }
  }, [userId]);
  if (!initialized) return <Loader color="red" />;
  return (
    <BrowserRouter>
      <ModalManager />
      <AppWrapper>
        <Header
          isMobile={isMobile}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
        {isMobile && (
          <MobileMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        )}
        <div className="app-body">
          <AppRouter childProps="fdfd"></AppRouter>
        </div>
      </AppWrapper>
    </BrowserRouter>
  );
};

export default App;
