/* eslint-disable no-console */
import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs, dataFromSnapshot } from "./firestoreService";
import {
  COLLECTION_EXCLUSIVEHOTEL64774,
  COLLECTION_HOTEL28355,
} from "./firebaseConstants";

export function fetchHotel28355FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_HOTEL28355);
  return collectionWithArgs(ref, args || []);
}
export function listenToHotel28355FromFirestore(id: string) {
  return db.collection(COLLECTION_HOTEL28355).doc(id);
}

export async function fetchAllHotelsExclusiveOfferFromFirestore(
  hotelsArgs?: IQueryConfigArgs[],
  exclusiveOfferArgs?: IQueryConfigArgs[],
) {
  const hotels: any[] = [];
  try {
    const ref = db.collection(COLLECTION_HOTEL28355);
    const hotelsRef = await collectionWithArgs(ref, hotelsArgs || []).get();
    const promise = hotelsRef.docs.map(async (doc: any) => {
      const hotel = dataFromSnapshot(doc);
      const exclusiveOffersRef = db
        .collection(COLLECTION_HOTEL28355)
        .doc(doc.id)
        .collection(COLLECTION_EXCLUSIVEHOTEL64774);
      const exclusiveOffersData = await collectionWithArgs(
        exclusiveOffersRef,
        exclusiveOfferArgs || [],
      ).get();

      hotel.exclusiveOffers = exclusiveOffersData.docs.map(
        (docExclusiveOffer: any) => ({
          ...dataFromSnapshot(docExclusiveOffer),
        }),
      );
      hotels.push(hotel);
    });
    await Promise.all(promise);
    return hotels.filter((v) => v.exclusiveOffers.length > 0);
  } catch (error) {
    console.log(error);
  }
}
