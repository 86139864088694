import styled from "styled-components";

// theme is now fully typed
export const MobileMenuWrapper = styled.div<{ showMenu: boolean }>`
  background-color: #37a7cd;
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(${({ showMenu }) => (showMenu ? "0" : "-100%")});
  transition: all 0.35s ease;
  display: flex;
  flex-direction: column;
  .mobile-menu {
    &-title {
      font-size: 24px;
      color: #eccd47;
      text-align: center;
      padding: 30px 20px 0px;
      font-weight: 900;
      font-family: "Roboto";
    }
    &-sub-title {
      font-size: 32px;
      color: #fff;
      text-align: center;
      font-family: "Roboto";
      margin-bottom: 10px;
    }
    &-items {
      display: flex;
      flex-direction: column;
      .ant-divider {
        background-color: #fff;
      }
      flex: 1;
      justify-content: center;
      align-items: center;
    }
    &-item {
      font-size: 24px;
      color: #fff;
      font-weight: 100;
      padding: 13px 20px;
      font-family: "Roboto";
    }
    &-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      border-top: 1px solid #fff;
      padding: 20px;
    }
    &-btn {
      background-color: #37a7cd;
      height: 64px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
    }
    &-phone-number {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      &-icon {
        font-size: 30px;
        color: #ffffff;
        margin-right: 5px;
      }
      &-text {
        text-align: center;
        a {
          color: #ffffff;
          margin-right: 20px;
        }
      }
      &-first {
        font-size: 18px;
        line-height: 30px;
      }
    }
    &-contact-us {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 10px;
      margin-bottom: 10px;
      gap: 5px;
      color: #ffffff;
      &-icon {
        font-size: 21px;
        color: #ffffff;
      }

      &-text {
        color: #ffffff;
        margin-right: 20px;
        text-align: center;
        > span {
          font-weight: 700;
          padding-right: 10px;
        }
      }
      &-title {
        font-size: 1.6em;
        font-weight: 700;
        margin-bottom: 10px;
        color: #eccd47;
      }
    }
  }
`;
