/* eslint-disable no-console */
import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { orderBy } from "lodash";
import { Collapse } from "../../atoms";
import { periodTypeOptions } from "../../../enums";
import { CheckboxGroup } from "../CheckboxGroup/CheckboxGroup";
import { ICity } from "../../../common/typings";
import { Select } from "../../atoms/Select/Select";
import { IHotel28355 } from "../../../common/types";
import { HotelFilterWrapper } from "./Hotel.style";
const { Panel } = Collapse;
// const searchParams = new URLSearchParams({
//   cities: "all",
//   period: "all",
// });
// const handelUrlChange = (
//   history: any,
//   name: string,
//   value: CheckboxValueType[],
//   url: string,
// ) => {
//   searchParams.set(name, `${value.join("-")}`);
//   history(`/${url}?${searchParams.toString()}`);
// };
export interface IProps {
  isTabletOrMobile: boolean;
  showFilter: () => void;
  params: any;
  url: string;
  hotelCities: ICity[];
  hotels: IHotel28355[];
}
export const HotelsFilters: React.FC<IProps> = ({
  isTabletOrMobile,
  showFilter,
  url,
  hotelCities,
  params,
  hotels,
}) => {
  const history = useNavigate();
  const [period, setPeriod] = React.useState<CheckboxValueType[]>([]);
  const [cities, setCities] = React.useState<CheckboxValueType[]>([]);
  const searchParams = new URLSearchParams(params);
  const handelUrlChange = (name: string, value: CheckboxValueType[]) => {
    searchParams.set(name, `${value.join("-")}`);
    history(`/${url}?${searchParams.toString()}`);
  };
  const handelChange = (name: string) => (value: CheckboxValueType[]) => {
    handelUrlChange(name, value);
  };
  const handelHotelSelectChange = (value: string) => {
    handelUrlChange("hotel-id", [value]);
  };
  React.useEffect(() => {
    if (params?.city) {
      setCities(params.city.split("-"));
      searchParams.set("city", params.city);
    }
    if (params?.period) {
      setPeriod(params.period.split("-"));
      searchParams.set("period", params.period);
    }
  }, [params]);
  console.log(hotelCities);
  return (
    <HotelFilterWrapper>
      <div className="package-filter-header">
        <span>Διαμορφώστε την αναζήτηση σας</span>
        {isTabletOrMobile && <CloseCircleOutlined onClick={showFilter} />}
      </div>
      <div className="package-filter-body">
        <Collapse
          defaultActiveKey={["3"]}
          expandIconPosition="start"
          bordered={false}
          className="package-filter-collapse"
        >
          <Panel header="Ξενοδοχεία" key="1">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Search Hotel"
              optionFilterProp="label"
              filterOption={(input, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={params["hotel-id"]}
              onChange={handelHotelSelectChange}
              options={orderBy(hotels, ["name"]).map((hotel) => ({
                value: hotel.id,
                label: hotel.name,
              }))}
              allowClear
            />
          </Panel>
          <Panel header="Πόλεις" key="2">
            <CheckboxGroup
              plainOptions={orderBy(hotelCities, ["label"]) as any[]}
              defaultCheckedList={cities}
              onChange={handelChange("city")}
              checkAllName={"Όλες"}
            />
          </Panel>
          <Panel header="Περίοδος" key="3">
            <CheckboxGroup
              plainOptions={periodTypeOptions as any[]}
              defaultCheckedList={period}
              onChange={handelChange("period")}
              checkAllName={"Όλες"}
            />
          </Panel>
        </Collapse>
      </div>
    </HotelFilterWrapper>
  );
};
