/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { listenToPackageFromFirestore } from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { listenToSelectedPackage } from "../../App/redux/data/package/packageActions";
import { ItemNotFound, Loader } from "../../App/components/molecules";
import { PackageDetails } from "../../App/components/organisms";

const PackageViewPage: React.FC = () => {
  const params = useParams();
  const packageId = params.packageId || "";
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const { loading, error } = useSelector((state: any) => state.async);

  userFirestoreDoc({
    query: () => listenToPackageFromFirestore(packageId),
    data: listenToSelectedPackage,
    deps: [packageId],
    shouldExecute: packageId !== "new",
  });

  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="Package"
        itemId={"sd"}
      />
    );
  }
  return (
    <PackageDetails
      travelPackage={selectedPackage || {}}
      packageId={packageId}
    />
  );
};

export default PackageViewPage;
