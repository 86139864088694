/* eslint-disable no-console */
import { CloseCircleOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { orderBy } from "lodash";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ICategory, ICity, IHotel } from "../../../common/typings";
import { getYearMonthsOptions } from "../../../common/util/util";
import { Collapse } from "../../atoms";
import { CheckboxGroup } from "../../molecules";
import { PackageFilterWrapper } from "./PackageListFrontEnd.style";
const { Panel } = Collapse;
const searchParams = new URLSearchParams({
  from: "all",
  to: "all",
  when: "all",
  category: "all",
  price: "0_5000",
});
const handelUrlChange = (
  history: any,
  name: string,
  value: CheckboxValueType[],
  url: string,
) => {
  searchParams.set(name, `${value.join("_")}`);
  history(`/${url}?${searchParams.toString()}`);
};
const marks = {
  0: "€0",
  5000: "€5000",
};
const departureCitiesOptions = [
  { label: "Λάρνακα", value: "LCA" },
  { label: "Πάφος", value: "PFO" },
];

const packageTypeOptions = [
  { label: "Οργανωμένα", value: "ORGANIZED" },
  { label: "Ατομικά", value: "INDIVIDUAL" },
];
export interface IPackageFilterProps {
  cities: ICity[];
  categories: ICategory[];
  isTabletOrMobile: boolean;
  showFilter: () => void;
  params: any;
  hotels: IHotel[];
  url: string;
}
const PackageFilter: React.FC<IPackageFilterProps> = ({
  cities,
  hotels,
  categories,
  isTabletOrMobile,
  showFilter,
  params,
  url,
}) => {
  const history = useNavigate();
  const [priceRange, setPriceRange] = React.useState<[number, number]>([
    0, 5000,
  ]);
  const [packageType, setPackageType] = React.useState<CheckboxValueType[]>([]);
  const [departureCities, setDepartureCities] = React.useState<
    CheckboxValueType[]
  >([]);
  const [departureMonths, setDepartureMonths] = React.useState<
    CheckboxValueType[]
  >([]);
  const [destinations, setDestinations] = React.useState<CheckboxValueType[]>(
    [],
  );
  const [selectedCategories, setSelectedCategories] = React.useState<
    CheckboxValueType[]
  >([]);
  const [selectedHotels, setSelectedHotels] = React.useState<
    CheckboxValueType[]
  >([]);
  const { formatMessage } = useIntl();

  const handelDepartureCitiesOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "from", value, url);
  };
  const handelDepartureMonthsOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "when", value, url);
  };
  const handelDestinationsChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "to", value, url);
  };
  const handelCategoriesChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "category", value, url);
  };
  const handelPriceRangeChange = (value: [number, number]) => {
    const [min, max] = value;
    handelUrlChange(history, "price", [min, max], url);
  };
  const handelHotelsChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "hotels", value, url);
  };
  const handelPackageTypeOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange(history, "type", value, url);
  };
  React.useEffect(() => {
    if (params?.from) {
      setDepartureCities(params.from.split("_"));
      searchParams.set("from", params.from);
    }
    if (params?.when) {
      setDepartureMonths(params.when.split("_"));
      searchParams.set("when", params.when);
    }
    if (params?.category) {
      setSelectedCategories(params.category.split("_"));
      searchParams.set("category", params.category);
    }
    if (params?.to) {
      setDestinations(params.to.split("_"));
      searchParams.set("to", params.to);
    }
    if (params?.hotels) {
      setSelectedHotels(params.hotels.split("_"));
      searchParams.set("hotels", params.hotels);
    }
    if (params?.type) {
      setPackageType(params.type.split("_"));
      searchParams.set("type", params.type);
    }
    params?.price && setPriceRange(params.price.split("_"));
  }, [params]);
  return (
    <PackageFilterWrapper>
      <div className="package-filter-header">
        <span>
          <FormattedMessage id="MHa5hq" defaultMessage="Refine your search" />
        </span>
        {isTabletOrMobile && <CloseCircleOutlined onClick={showFilter} />}
      </div>
      <div className="package-filter-body">
        <Collapse
          defaultActiveKey={[]}
          expandIconPosition="start"
          bordered={false}
          className="package-filter-collapse"
        >
          <Panel header={"Τύπος"} key="1">
            <CheckboxGroup
              plainOptions={packageTypeOptions}
              defaultCheckedList={packageType}
              onChange={handelPackageTypeOnChange}
              checkAllName={formatMessage({
                id: "ToO3/h",
                defaultMessage: "Any",
              })}
            />
          </Panel>
          <Panel
            header={formatMessage({
              id: "dM+p3/",
              defaultMessage: "From",
            })}
            key="11"
          >
            <CheckboxGroup
              plainOptions={departureCitiesOptions}
              defaultCheckedList={departureCities}
              onChange={handelDepartureCitiesOnChange}
              checkAllName={formatMessage({
                id: "7bDzLt",
                defaultMessage: "From any where",
              })}
            />
          </Panel>
          <Panel
            header={formatMessage({
              id: "wc9wE6",
              defaultMessage: "Price Range",
            })}
            key="2"
          >
            <Slider
              marks={marks}
              range={{ draggableTrack: true }}
              onAfterChange={handelPriceRangeChange}
              defaultValue={priceRange}
              max={5000}
              step={10}
              min={0}
            />
          </Panel>
          <Panel header={"Μήνας"} key="3">
            <CheckboxGroup
              plainOptions={getYearMonthsOptions()}
              defaultCheckedList={departureMonths}
              onChange={handelDepartureMonthsOnChange}
              checkAllName={"Οποιοδήποτε Μήνα"}
            />
          </Panel>
          <Panel
            header={formatMessage({
              id: "VKb1MS",
              defaultMessage: "Categories",
            })}
            key="4"
          >
            <CheckboxGroup
              plainOptions={categories.map((category) => ({
                value: category.code,
                label: category.name,
              }))}
              defaultCheckedList={selectedCategories}
              onChange={handelCategoriesChange}
              checkAllName={formatMessage({
                id: "ToO3/h",
                defaultMessage: "Any",
              })}
            />
          </Panel>
          <Panel
            header={formatMessage({
              defaultMessage: "Top Hotels",
              id: "8FlWuc",
            })}
            key="41"
          >
            <CheckboxGroup
              plainOptions={hotels.map((hotel) => ({
                value: hotel.id,
                label: hotel.name,
              }))}
              defaultCheckedList={selectedHotels}
              onChange={handelHotelsChange}
            />
          </Panel>
          <Panel
            header={formatMessage({
              id: "BzQPM+",
              defaultMessage: "Destination",
            })}
            key="5"
          >
            <CheckboxGroup
              plainOptions={orderBy(cities, ["name"]).map((city) => ({
                value: city.code,
                label: city.name,
              }))}
              defaultCheckedList={destinations}
              onChange={handelDestinationsChange}
              checkAllName={formatMessage({
                id: "gnXrq0",
                defaultMessage: "To any where",
              })}
            />
          </Panel>
        </Collapse>
      </div>
    </PackageFilterWrapper>
  );
};

export default PackageFilter;
