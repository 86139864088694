import React from "react";
import { Link } from "react-router-dom";
import { LoginOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown } from "../../atoms";

export interface ILeftMenuProps {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
  currentUser: any;
}

export const LeftMenu: React.FC<ILeftMenuProps> = ({
  authenticated,
  login,
  logout,
  currentUser,
}) => {
  const items = [
    {
      label: `${currentUser?.name} ${currentUser?.surname}`,
      key: "item-1",
    },
    {
      label: (
        <Link to="#" onClick={logout}>
          {" "}
          Log out
        </Link>
      ),
      key: "item-2",
    },
  ];
  return (
    <div style={{ width: 150, textAlign: "right" }}>
      {authenticated ? (
        <Dropdown menu={{ items }}>
          <Avatar
            size={40}
            style={{
              backgroundColor: "#0c3d72",
              verticalAlign: "middle",
              fontSize: 14,
            }}
          >
            {currentUser?.avatar || "Hi!"}
          </Avatar>
        </Dropdown>
      ) : (
        <Button onClick={login} type="dashed" icon={<LoginOutlined />}>
          Σύνδεση
        </Button>
      )}
    </div>
  );
};
