/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { COLLECTION_EMAIL } from "./firebaseConstants";
import { db } from "./firestoreService";

const partnerEmail = process.env.REACT_APP_SITE_EMAIL!;
export function sendContactUsForm(doc: any) {
  return db.collection(COLLECTION_EMAIL).add({
    to: partnerEmail || "reservations@poupas.com.cy",
    message: {
      subject: `Contact us Form`,
      text: JSON.stringify(doc) || "",
      html: ` Name: <strong>${doc.name}<strong> <br />
      Email: <strong>${doc.email}<strong> <br />
      Phone Number: <strong>${doc.phoneNumber}<strong> <br />
      Message: <p>${doc?.message || ""}<p>`,
    },
  });
}

export function sendRequestPackageForm(doc: any) {
  return db.collection(COLLECTION_EMAIL).add({
    to: partnerEmail || "reservations@poupas.com.cy",
    message: {
      subject: `Request Package Form`,
      text: JSON.stringify(doc) || "",
      html: ` Name: <strong>${doc.name}</strong> <br />
      Email: <strong>${doc.email}</strong> <br />
      Phone Number: <strong>${doc.phoneNumber}</strong> <br />
      Country: <strong>${doc.country}</strong> <br />
      City: <strong>${doc.city}</strong> <br />
      Date: <strong>${doc.date}</strong> <br />
      Adults: <strong>${doc.adults}</strong> <br />
      Children: <strong>${doc.children}</strong> <br />
      Infants: <strong>${doc.infants}</strong> <br />
      Room Number: <strong>${doc.roomNumber}</strong> <br />
      Url: <strong>${doc.url}</strong> <br />
      Message: <p>${doc?.message || ""}</p>`,
    },
  });
}
export function sendRequestSelectedPackageForm(doc: any) {
  return db.collection(COLLECTION_EMAIL).add({
    to: partnerEmail || "reservations@poupas.com.cy",
    message: {
      subject: `Request ${doc.packageTitle} Package Form`,
      text: JSON.stringify(doc) || "",
      html: ` Name: <strong>${doc.name}</strong> <br />
      Email: <strong>${doc.email}</strong> <br />
      Phone Number: <strong>${doc.phoneNumber}</strong> <br />
      Package Title: <strong>${doc.packageTitle}</strong> <br />
      Departure Date: <strong>${doc.departureDate}</strong> <br />
      Return Date: <strong>${doc.returnDate}</strong> <br />
      Hotel Name: <strong>${doc.hotelName}</strong> <br />
      Rooms: <strong>${doc.rooms}</strong> <br />
      packageUrl: <strong>${doc.packageUrl}</strong> <br />
      Message: <p>${doc?.message || ""}</p>`,
    },
  });
}
export function sendRequestHotelForm(doc: any) {
  return db.collection(COLLECTION_EMAIL).add({
    to: partnerEmail || "reservations@poupas.com.cy",
    message: {
      subject: `Request Hotel Form`,
      text: JSON.stringify(doc) || "",
      html: ` Name: <strong>${doc.name}</strong> <br />
      Email: <strong>${doc.email}</strong> <br />
      Phone Number: <strong>${doc.phoneNumber}</strong> <br />
      City: <strong>${doc.city}</strong> <br />
      Date: <strong>${doc.date}</strong> <br />
      Adults: <strong>${doc.adults}</strong> <br />
      Children: <strong>${doc.children}</strong> <br />
      Infants: <strong>${doc.infants}</strong> <br />
      Room Number: <strong>${doc.roomNumber}</strong> <br />
      Message: <p>${doc?.message || ""}</p>`,
    },
  });
}
