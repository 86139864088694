/* eslint-disable no-console */
import React from "react";
import { find } from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { IDestination, IPackageHotelPublic } from "../../../common/typings";
import { Divider } from "../../atoms";
import { IPackage } from "../../../common/types";
import { PackageHotelItem } from "../../molecules";
import { formatReadableCity } from "../../../common/util/util";
import { PackageHotelsWrapper } from "./PackageViewFrontEnd.style";
import { PackageHotelView } from "./PackageHotelView";

export interface IPackageHotelsFrontendProps {
  destinations: IDestination[];
  hotels: IPackageHotelPublic[];
  selectedHotels: IPackageHotelPublic[];
  selectHotel: (hotel: IPackageHotelPublic) => void;
  isTabletOrMobile: boolean;
  isMobile: boolean;
  discount: number;
  selectedRooms: any[];
  travelPackage: IPackage;
}
export const PackageHotels: React.FC<IPackageHotelsFrontendProps> = ({
  destinations,
  selectHotel,
  selectedHotels,
  hotels,
  isTabletOrMobile,
  isMobile,
  discount,
  selectedRooms,
  travelPackage,
}) => {
  const [viewedHotel, setViewedHotel] = React.useState<IPackageHotelPublic>();
  const [visible, setVisible] = React.useState(false);
  const showHotel = (currentHotel: IPackageHotelPublic) => {
    setVisible(true);
    setViewedHotel(currentHotel);
  };
  const hideViewedHotel = () => {
    setVisible(false);
  };
  const getCityHotels = (cityId: string) =>
    hotels.filter((hotel: IPackageHotelPublic) => hotel.cityId === cityId);
  return (
    <PackageHotelsWrapper>
      <div className="package-hotels-title">
        <FormattedMessage
          description="Hotels"
          defaultMessage="Hotels"
          id="qrGoSh"
        />
      </div>
      <div className="package-hotels-des">
        <FormattedMessage
          description="Prices listed below are per person"
          defaultMessage=" Prices listed below are per person"
          id="l8rbrg"
        />
      </div>
      {destinations.map(({ cityId, city, checkInDate, checkOutDate }) => (
        <div className="package-hotels-list" key={cityId}>
          <Divider>
            <div className="package-hotels-title">
              {formatReadableCity(city)} (
              {moment(checkInDate).format("DD/MM/YYYY")}-{" "}
              {moment(checkOutDate).format("DD/MM/YYYY")})
            </div>
          </Divider>
          <div className="package-hotels-prices">
            {getCityHotels(cityId).map((packageHotel: IPackageHotelPublic) => (
              <PackageHotelItem
                key={packageHotel.id}
                packageHotel={packageHotel}
                selectHotel={selectHotel}
                isSelectedHotel={
                  !!find(selectedHotels, ["id", packageHotel.id])
                }
                showHotel={showHotel}
                isTabletOrMobile={isTabletOrMobile}
                discount={discount}
                selectedRooms={selectedRooms}
                travelPackage={travelPackage}
              />
            ))}
          </div>
        </div>
      ))}
      <PackageHotelView
        viewedHotel={viewedHotel}
        mealsType={viewedHotel?.mealsType || ""}
        visible={visible}
        onClose={hideViewedHotel}
        isMobile={isMobile}
      />
    </PackageHotelsWrapper>
  );
};
