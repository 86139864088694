import React from "react";
import { Footer } from "../../App/components/molecules";
import { HomePageWrapper } from "./HotelPage.style";

const HotelPage: React.FC = () => (
  <HomePageWrapper>
    <div className="scroll-full">
      <div className="standard-home-container">
        <div className="standard-full">
          <div className="">
            <div className=" full-height keep-scroll">
              <div className="bgimg bg-hotel"></div>
              <div className="heading">
                <div
                  className="flights-search-header"
                  style={{ maxWidth: 800, margin: "20px auto" }}
                >
                  Με περηφάνια ανακοινώνουμε τη συνεργασία μας με τη Παγκόσμιας
                  Εμβέλειας ιστοσελίδα εύρεσης καλύτερων τιμών για ξενοδοχεία
                  Hotelscombined.com
                </div>
                <a
                  href="https://www.dpbolvw.net/click-101076804-10937588"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#FFFFFF" }}
                >
                  <img
                    src="/assets/Hotels-Search-Announcement.jpg"
                    alt="Hotels-Search-Announcement"
                  />
                  <img
                    src="https://www.lduhtrp.net/image-101076804-10788238"
                    width="1"
                    height="1"
                  />
                </a>
              </div>
              <div className="space-footer"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="section ">
        <div className="container pad-top pad-bottom ">
          <div className="section-heading">
            <h4 className="blue-color">
              Go on holidays without breaking the bank
            </h4>
            <p className="pad-bottom">
              Compare all the top travel sites in one simple search and find
              just what you’re after. That easy.
            </p>
            <img
              style={{ width: "90%", maxWidth: 600 }}
              src="/assets/images/compare_hotel_sites.jpg"
              alt=""
            />

            <p className="blue-color">
              <br />
              and many more!
            </p>
          </div>
        </div>
      </div>
      <Footer isMobile={false} />
    </div>
  </HomePageWrapper>
);

export default HotelPage;
