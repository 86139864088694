import { IDestination, IFlight, IImageGroup } from "../../../common/typings";
import { IPackage } from "../../../common/types";
/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchPackagesFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import {
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  FETCH_PACKAGES,
  LISTEN_TO_SELECTED_PACKAGE,
  CLEAR_PACKAGES,
  CLEAR_SELECTED_PACKAGE,
  LISTEN_TO_PACKAGE_FLIGHTS,
  LISTEN_TO_PACKAGE_DESTINATIONS,
} from "./packageConstants";

export function listenToPackages(packages: IPackage[]) {
  return {
    type: FETCH_PACKAGES,
    payload: packages,
  };
}

export function listenToSelectedPackage(travelPackage: IPackage) {
  return {
    type: LISTEN_TO_SELECTED_PACKAGE,
    payload: travelPackage,
  };
}

export function listenToPackageFlights(flights: IFlight[]) {
  return {
    type: LISTEN_TO_PACKAGE_FLIGHTS,
    payload: flights,
  };
}
export function listenToPackageDestinations(destinations: IDestination[]) {
  return {
    type: LISTEN_TO_PACKAGE_DESTINATIONS,
    payload: destinations,
  };
}
export function listenToPackageDestinationsImages(
  imagesGroups: IImageGroup[],
  destinationId: string,
) {
  return {
    type: LISTEN_TO_PACKAGE_DESTINATIONS,
    payload: { imagesGroups, destinationId },
  };
}
export function clearSelectedPackage() {
  return {
    type: CLEAR_SELECTED_PACKAGE,
  };
}

export function createPackage(travelPackage: IPackage) {
  return {
    type: CREATE_PACKAGE,
    payload: travelPackage,
  };
}

export function updatePackage(travelPackage: IPackage) {
  return {
    type: UPDATE_PACKAGE,
    payload: travelPackage,
  };
}

export function deletePackage(packageId: string) {
  return {
    type: DELETE_PACKAGE,
    payload: packageId,
  };
}

export function clearPackages() {
  return {
    type: CLEAR_PACKAGES,
  };
}
