/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/await-thenable */
import React from "react";
import { Modal } from "../../components/atoms";
export interface IModalProps {
  children: React.ReactNode;
  title?: string;
  onSave?: () => void;
  loading?: boolean;
  closable?: boolean;
  okText?: string;
  footer?: any;
  maskClosable?: boolean;
  bodyStyle?: any;
  width?: number;
  onCancel?: () => void;
  cancelText?: string;
}

export const ModalWrapper: React.FC<IModalProps> = ({
  children,
  title,
  onSave,
  loading,
  closable = true,
  okText,
  footer,
  maskClosable = false,
  bodyStyle,
  width,
  onCancel,
  cancelText = "Close",
}) => {
  const handleOk = async () => {
    try {
      onSave && (await onSave());
    } catch (err) {
      throw err;
    }
  };
  return (
    <Modal
      title={title}
      onOk={handleOk}
      confirmLoading={loading}
      open={true}
      closable={closable}
      maskClosable={maskClosable}
      okText={okText}
      cancelText={cancelText}
      maskStyle={{ backgroundColor: "rgb(255 255 255 / 90%)" }}
      cancelButtonProps={{ disabled: !closable }}
      footer={footer}
      bodyStyle={bodyStyle}
      width={width}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
};
