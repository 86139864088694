/* eslint-disable no-console */
import { LockOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Divider } from "antd";
import { Alert, Button, Form, Input, Logo } from "../../atoms";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal, openModal } from "../../../redux/data/modals/modalReducer";
import { ICustomer14860Input } from "../../../common/types";
import { addCustomer14860ToFirestore } from "../../../firestore/firestoreService";
import { InputField } from "../../molecules";
import { inputRuleLatenOnly } from "../../../common/util/util";
import { LoginLogoWrapper } from "./LoginForm.style";

export const SignInFormPoupas: React.FC = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleOnClose = () => {
    dispatch(closeModal());
  };
  const handleSubmit = async () => {
    try {
      const values: ICustomer14860Input = await form.validateFields();
      setLoading(true);
      await addCustomer14860ToFirestore({
        name: values.name,
        status: true,
        surname: values.surname,
        email: values.email,
        password: values.password,
        partner: "POUPAS",
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const handleLogin = () => {
    dispatch(
      openModal({
        modalType: "LoginFormPoupas",
        modalProps: { title: "Σύνδεση" },
      }),
    );
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      closable={true}
      okText={""}
      cancelText=""
      onCancel={handleOnClose}
      footer={false}
    >
      <LoginLogoWrapper>
        <Logo />
      </LoginLogoWrapper>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <InputField
          label="Όνομα (Λατινικοί Χαρακτήρες μόνο)"
          name="name"
          rules={[
            {
              required: true,
              message: "Missing Όνομα (Λατινικοί Χαρακτήρες μόνο)",
            },
            inputRuleLatenOnly,
          ]}
          placeholder="Όνομα (Λατινικοί Χαρακτήρες μόνο)"
        />
        <InputField
          label="Επίθετο (Λατινικοί Χαρακτήρες μόνο)"
          name="surname"
          rules={[
            {
              required: true,
              message: "Missing Επίθετο (Λατινικοί Χαρακτήρες μόνο)",
            },
            inputRuleLatenOnly,
          ]}
          placeholder="Επίθετο (Λατινικοί Χαρακτήρες μόνο)"
        />
        <InputField
          label="Διεύθυνση Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Missing Διεύθυνση Email",
            },
          ]}
          placeholder="Διεύθυνση Email"
        />

        <Form.Item
          name="password"
          label="Κωδικός"
          rules={[{ required: true, message: "Κωδικός" }]}
          help=" Ασφαλείας (Ο κωδικός ασφαλείας σας πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες και να συνδυάζει γράμματα με λατινικούς χαρακτήρες και αριθμούς)"
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Button type="primary" onClick={handleSubmit} block>
          {" "}
          Εγγραφή
        </Button>
        <Divider>Έχετε ήδη λογαριασμό;</Divider>
        <div className="sign-in-up">
          {" "}
          <Button type="link" onClick={handleLogin} block>
            {" "}
            Σύνδεση
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

// Password - Κωδικός
