import styled from "styled-components";
export const CartWrapper = styled.div`
  /* padding: 20px;
  padding-top: 10px; */
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .cart {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
    }
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: bold;
      font-family: "Avenir";
      padding: 10px 20px;
    }
    &-sub-title {
      color: #22335d;
      font-size: 14px;
      font-weight: bold;
      font-family: "Avenir";
    }
    &-body {
      margin-top: 10px;
    }
    &-hotel {
      flex: 1;
      overflow: hidden;
    }
    &-passengers {
      overflow-y: auto;
      height: 100%;
      padding: 20px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        border: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        outline: 1px solid slategrey;
      }
    }

    &-close {
      right: 20px;
      top: 2px;
      font-size: 21px;
      position: relative;
    }

    &-noHotels {
      font-size: 18px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      padding: 20px;
      color: #aaaaaa;
      margin-top: 50px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
  .package-flights-list {
    margin-top: 0px;
    box-shadow: none;
    padding: 0px;
  }
`;

export const CartTotalBtnWrapper = styled.div`
  background-color: #fff;
  border-top: 1px solid #e9eef2;
  padding-bottom: 5px;
  .cart-total {
    &-total {
      display: flex;
      justify-content: space-between;
      margin: 0px;
      padding: 10px 20px 0px;
      color: #22335d;
      font-size: 24px;
      font-family: "Avenir";
      &-price {
        font-weight: 900;
      }

      border-bottom: 1px solid #f8f8f8;
    }
    &-cancellation-policy {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin: 0px;
      padding: 10px 20px 0px;
      color: #22335d;
      font-size: 14px;
      font-family: "Avenir";
      border-bottom: 1px solid #f8f8f8;
      strong {
        font-weight: 700;
      }
    }
    &-coupon-total {
      display: flex;
      justify-content: space-between;
      margin: 0px;
      padding: 10px 20px 0px;
      color: #fa5a5a;
      font-size: 18px;
      font-family: "Avenir";
      &-price {
        font-weight: 900;
      }
      border-bottom: 1px solid #f8f8f8;
    }
    &-coupon {
      padding: 20px;
      text-align: right;
      border-bottom: 1px solid #f8f8f8;
      &-text {
        margin-bottom: 15px;
        font-weight: 700;
      }
      &-space {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
      }
    }
    &-text {
      margin-bottom: 15px;
      text-align: center;
    }
    &-discount-text {
      color: #ffc926;
      font-size: 16px;
      font-weight: bold;
      font-family: "Avenir";
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #111;
      margin: 5px 10px;
    }
    &-discount {
      position: relative;
      color: #22335d;
      font-size: 18px;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background: red;
        position: absolute;
        bottom: 23px;
        left: 0;
      }
    }
    &-date {
      display: flex;
      justify-content: center;
      margin: 0px;
      padding: 10px 20px 0px;
      color: #22335d;
      font-size: 16px;
      font-family: "Avenir";
      font-weight: 700;
    }
    &-btn {
      background-color: #1d3bf8;
      height: 64px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
      width: 100%;
    }
    &-actions {
      padding: 20px;
      background-color: #eee;
      margin: 20px;
      box-shadow: rgb(0 0 0 / 11%) 1px 0px 3px 0px;
    }
  }
`;
